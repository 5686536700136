"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomSearchParams = void 0;
const react_router_dom_1 = require("react-router-dom");
const useCustomSearchParams = () => {
    const [search, setSearch] = (0, react_router_dom_1.useSearchParams)();
    const searchAsObject = Object.fromEntries(new URLSearchParams(search));
    return [searchAsObject, setSearch];
};
exports.useCustomSearchParams = useCustomSearchParams;
