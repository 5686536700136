"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingPagesContainer = void 0;
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const Sentry = __importStar(require("@sentry/react"));
const react_error_boundary_1 = require("react-error-boundary");
const polaris_1 = require("@shopify/polaris");
const AccountContext_1 = require("contexts/AccountContext");
const StyledMainContainer_1 = require("components/Dashboard/StyledMainContainer");
const CreateTrackingPageModal_1 = require("components/Dashboard/TrackingPages/CreateTrackingPageModal");
const DeleteTrackingPageModal_1 = require("components/Dashboard/TrackingPages/DeleteTrackingPageModal");
const ListTrackingPages_1 = require("components/Dashboard/TrackingPages/ListTrackingPages");
const TrackingPageAlert_1 = require("components/Dashboard/TrackingPages/TrackingPageAlert");
const EmptyState_1 = require("components/Dashboard/TrackingPages/EmptyState");
const Loading_1 = require("components/Dashboard/TrackingPages/Loading");
const graphql_1 = require("components/Dashboard/TrackingPages/graphql");
const styles_1 = require("./styles");
const alertOptions = {
    CREATE_TRACKING_PAGE: 'create-tracking-page',
    DELETE_TRACKING_PAGE: 'delete-tracking-page',
    SET_DEFAULT_TRACKING_PAGE_URL: 'set-default-tracking-page-url'
};
const useTrackingPageQueries = () => {
    var _a, _b, _c, _d, _e;
    const { account } = (0, AccountContext_1.useAccount)();
    const getTrackingPages = (0, client_1.useQuery)(graphql_1.GET_TRACKING_PAGES, {
        skip: !(account === null || account === void 0 ? void 0 : account.id),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { accountId: account.id }
    });
    const getThemes = (0, client_1.useQuery)(graphql_1.GET_THEMES, {
        skip: !(account === null || account === void 0 ? void 0 : account.id),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { accountId: account.id },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError(error) {
            Sentry.captureException({
                message: 'Timeout when requesting shopify theme data for the Tracking Page Creator.',
                trace: error
            });
        }
    });
    const trackingPagesQueryError = getTrackingPages.error;
    const shopifyThemesQueryError = getThemes.error;
    const loading = getTrackingPages.loading || getThemes.loading;
    const trackingPages = (_b = (_a = getTrackingPages.data) === null || _a === void 0 ? void 0 : _a.externalTrackingPage) !== null && _b !== void 0 ? _b : [];
    const themes = (_e = (_d = (_c = getThemes.data) === null || _c === void 0 ? void 0 : _c.shopifyGetThemes) === null || _d === void 0 ? void 0 : _d.themes) !== null && _e !== void 0 ? _e : [];
    return {
        trackingPagesEmpty: trackingPages.length === 0,
        trackingPagesQueryError,
        shopifyThemesQueryError,
        loading,
        themes,
        trackingPages
    };
};
const TrackingPagesContainer = () => {
    const handleError = (0, react_error_boundary_1.useErrorHandler)();
    const [createTrackingPageModalIsActive, setCreateTrackingPageModalIsActive] = (0, react_1.useState)(false);
    const [deleteTrackingPageModalIsActive, setDeleteTrackingPageModalIsActive] = (0, react_1.useState)(false);
    const [selectedTrackingPage, setSelectedTrackingPage] = (0, react_1.useState)(null);
    const [selectedAlert, setSelectedAlert] = (0, react_1.useState)('');
    const [selectedTitle, setSelectedTitle] = (0, react_1.useState)('');
    const divRef = (0, react_1.useRef)(null);
    const { trackingPagesEmpty, loading, themes, trackingPages, trackingPagesQueryError, shopifyThemesQueryError } = useTrackingPageQueries();
    const handleOpenCreateTrackingPageModal = (0, react_1.useCallback)(() => {
        setCreateTrackingPageModalIsActive(true);
    }, []);
    const handleCloseCreateTrackingPageModal = (0, react_1.useCallback)(() => {
        setCreateTrackingPageModalIsActive(false);
        requestAnimationFrame(() => { var _a, _b; return (_b = (_a = divRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('button')) === null || _b === void 0 ? void 0 : _b.focus(); });
    }, []);
    const handleOpenDeleteTrackingPageModal = (0, react_1.useCallback)(() => {
        setDeleteTrackingPageModalIsActive(true);
    }, []);
    const handleCloseDeleteTrackingPageModal = (0, react_1.useCallback)(() => {
        setSelectedTrackingPage(null);
        setDeleteTrackingPageModalIsActive(false);
    }, []);
    const handleScrollToTop = (0, react_1.useCallback)(() => {
        const container = document.querySelector('#dashboard-container');
        container === null || container === void 0 ? void 0 : container.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    if (trackingPagesQueryError) {
        handleError(Object.assign(Object.assign({}, trackingPagesQueryError), { message: 'The server could not be reached, please try again later.' }));
    }
    if (shopifyThemesQueryError) {
        handleError(Object.assign(Object.assign({}, shopifyThemesQueryError), { message: 'The Shopify API is currently unreachable. Please try reloading the page, or try again later.' }));
    }
    if (loading) {
        return react_1.default.createElement(Loading_1.Loading, null);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: 'fade show' },
            react_1.default.createElement(StyledMainContainer_1.StyledMainContainer, { className: 'tracking-pages' },
                react_1.default.createElement(styles_1.HeaderContainer, Object.assign({}, (!trackingPagesEmpty ? { ref: divRef } : {})),
                    react_1.default.createElement(styles_1.Header, { "aria-level": 1, className: 'header', role: 'heading' }, "Tracking Page Creator"),
                    !trackingPagesEmpty && (react_1.default.createElement(polaris_1.Button, { onClick: handleOpenCreateTrackingPageModal, variant: 'primary' }, "Create a Tracking Page"))),
                react_1.default.createElement(TrackingPageAlert_1.TrackingPageAlert, { active: selectedAlert === alertOptions.CREATE_TRACKING_PAGE, onDismiss: () => {
                        setSelectedAlert('');
                        setSelectedTitle('');
                    }, title: selectedTitle },
                    react_1.default.createElement("p", null,
                        "Success! Your new tracking page has been published in your Shopify store. Click the link below to preview your page. You can customize your page and add additional sections using the Shopify Theme Editor by clicking the ",
                        react_1.default.createElement(styles_1.Span, null, "Customize My Page"),
                        ' ',
                        "button.")),
                react_1.default.createElement(TrackingPageAlert_1.TrackingPageAlert, { active: selectedAlert === alertOptions.SET_DEFAULT_TRACKING_PAGE_URL, onDismiss: () => {
                        setSelectedAlert('');
                        setSelectedTitle('');
                    }, title: selectedTitle },
                    react_1.default.createElement("p", null, "You can edit your default tracking page URL at any time in your account settings.")),
                react_1.default.createElement(TrackingPageAlert_1.TrackingPageAlert, { active: selectedAlert === alertOptions.DELETE_TRACKING_PAGE, onDismiss: () => {
                        setSelectedAlert('');
                        setSelectedTitle('');
                    }, title: selectedTitle }),
                trackingPagesEmpty && (react_1.default.createElement(EmptyState_1.EmptyState, { divRef: divRef, onAction: handleOpenCreateTrackingPageModal })),
                !trackingPagesEmpty && (react_1.default.createElement(ListTrackingPages_1.ListTrackingPages, { onChangeSelectedTrackingPage: setSelectedTrackingPage, onChangeSuccessAlert: () => setSelectedAlert(alertOptions.SET_DEFAULT_TRACKING_PAGE_URL), onChangeSuccessTitle: setSelectedTitle, onOpenDeleteModal: handleOpenDeleteTrackingPageModal, onScrollToTop: handleScrollToTop, selectedTrackingPage: selectedTrackingPage, themes: themes, trackingPages: trackingPages })))),
        react_1.default.createElement(CreateTrackingPageModal_1.CreateTrackingPageModal, { active: createTrackingPageModalIsActive, onChangeSuccessAlert: () => setSelectedAlert(alertOptions.CREATE_TRACKING_PAGE), onChangeSuccessTitle: setSelectedTitle, onClose: handleCloseCreateTrackingPageModal }),
        react_1.default.createElement(DeleteTrackingPageModal_1.DeleteTrackingPageModal, { active: deleteTrackingPageModalIsActive, onChangeSuccessAlert: () => setSelectedAlert(alertOptions.DELETE_TRACKING_PAGE), onChangeSuccessTitle: setSelectedTitle, onClose: handleCloseDeleteTrackingPageModal, onScrollToTop: handleScrollToTop, selectedTrackingPage: selectedTrackingPage })));
};
exports.TrackingPagesContainer = TrackingPagesContainer;
