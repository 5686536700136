"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackingCopy = void 0;
exports.trackingCopy = {
    loading: 'Loading Tracking Pages',
    fetchError: 'The server could not be reached, please try again later',
    noArchivedTrackingPagesHeader: 'No archived pages.',
    noArchivedTrackingPagesParagraph: 'Once pages are archived, you can find them here.',
    noTrackingPagesHeader: 'No pages yet.',
    noTrackingPagesParagraph: "Click 'new page' to create your first tracking page.",
    mainHeader: 'Tracking Page',
    tab1: 'All',
    tab2: 'Archived',
    button1: 'New Page',
    created: 'Created',
    updated: 'Last Updated',
    modalHeader1: 'New Tracking Page',
    modalHeader2: 'Edit Tracking Page',
    modalHeader3: 'Are you sure?',
    modalLabel1: 'Name',
    modalLabel2: 'URL',
    modalLabel3: "Once you delete this tracking page, there's no turning back.",
    modalLabel4: "If you archive this page, you won't be able to access it until it's restored.",
    modalRestoreLabel: 'Would you like to restore this tracking page?',
    uploadPrompt: 'Drop zip file here or click to upload',
    modalButton1: 'Cancel',
    modalButtonNew: 'Create Page',
    modalButtonEdit: 'Update',
    modalButtonDelete: 'Delete',
    modalButtonArchive: 'Archive',
    modalButtonRestore: 'Restore'
};
