"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profileCopy = void 0;
exports.profileCopy = {
    label1: 'Name',
    button1: 'Update Name',
    label2: 'Email Address',
    label3: 'Confirm Password',
    button2: 'Update Email'
};
