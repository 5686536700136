"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TRACKING_PAGE = exports.DELETE_TRACKING_PAGE = exports.UNARCHIVE_TRACKING_PAGE = exports.ARCHIVE_TRACKING_PAGE = exports.CONFIRM_UPLOAD = exports.GET_S3_URL = exports.CREATE_TRACKING_PAGE = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_TRACKING_PAGE = (0, client_1.gql) `
  mutation createTrackingPage($input: CreateTrackingPageInput!) {
    createTrackingPage(input: $input) {
      trackingPage {
        id
      }
    }
  }
`;
exports.GET_S3_URL = (0, client_1.gql) `
  mutation createTrackingPagePayloadSignedUrl(
    $input: CreateTrackingPagePayloadSignedUrlInput!
  ) {
    createTrackingPagePayloadSignedUrl(input: $input) {
      s3Path
      url
      version
    }
  }
`;
exports.CONFIRM_UPLOAD = (0, client_1.gql) `
  mutation payloadUploadSuccessful($input: PayloadUploadSuccessfulInput!) {
    payloadUploadSuccessful(input: $input) {
      success
    }
  }
`;
exports.ARCHIVE_TRACKING_PAGE = (0, client_1.gql) `
  mutation archiveTrackingPage($input: ArchiveTrackingPageInput!) {
    archiveTrackingPage(input: $input) {
      trackingPage {
        archivedAt
      }
    }
  }
`;
exports.UNARCHIVE_TRACKING_PAGE = (0, client_1.gql) `
  mutation unarchiveTrackingPage($input: UnarchiveTrackingPageInput!) {
    unarchiveTrackingPage(input: $input) {
      trackingPage {
        archivedAt
      }
    }
  }
`;
exports.DELETE_TRACKING_PAGE = (0, client_1.gql) `
  mutation deleteTrackingPage($input: DeleteTrackingPageInput!) {
    deleteTrackingPage(input: $input) {
      ok
    }
  }
`;
exports.UPDATE_TRACKING_PAGE = (0, client_1.gql) `
  mutation updateTrackingPage($input: UpdateTrackingPageInput!) {
    updateTrackingPage(input: $input) {
      trackingPage {
        id
      }
    }
  }
`;
