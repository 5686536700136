"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTemplatesGlobalConfig = exports.COLOR_LABELS = void 0;
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const react_hook_form_1 = require("react-hook-form");
const zod_1 = require("@hookform/resolvers/zod");
const react_router_dom_1 = require("react-router-dom");
const lodash_1 = require("lodash");
const polaris_1 = require("@shopify/polaris");
const AccountContext_1 = require("contexts/AccountContext");
const components_1 = require("@/common/components");
const errors_copy_1 = require("@/common/errors.copy");
const common_1 = require("@/emails/common");
const hooks_1 = require("@/common/hooks");
const layout_1 = require("@/common/components/layout");
const global_config_graphql_1 = require("./global-config.graphql");
const global_config_validation_1 = require("./global-config.validation");
const global_config_copy_1 = require("./global-config.copy");
exports.COLOR_LABELS = {
    accent: 'Accent color',
    text: 'Text color',
    buttonText: 'Button text color',
    background: 'Background color'
};
const COLOR_TYPES = Object.keys(exports.COLOR_LABELS);
const SOCIAL_DETAILS = [
    {
        name: 'instagram',
        label: 'Instagram',
        placeholder: 'https://instagram.com/gomalomo'
    },
    {
        name: 'x',
        label: 'X (Twitter)',
        placeholder: 'https://twitter.com/gomalomo'
    },
    {
        name: 'facebook',
        label: 'Facebook',
        placeholder: 'https://facebook.com/gomalomo'
    }
];
const DEFAULT_VALUES = {
    senderName: '',
    replyToEmail: '',
    logoImageUrl: '',
    logoWidth: 20,
    logoTargetLink: '',
    socials: {
        facebook: '',
        instagram: '',
        x: ''
    },
    colors: {
        accent: '#4141dd',
        background: '#ffffff',
        buttonText: '#ffffff',
        text: '#000000'
    },
    address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zip: ''
    }
};
const EmailTemplatesGlobalConfig = () => {
    const { account } = (0, AccountContext_1.useAccount)();
    const [showFormSuccess, setShowFormSuccess] = (0, react_1.useState)(false);
    const [showErrorBanner, setShowErrorBanner] = (0, react_1.useState)(false);
    const [showLeaveModal, setShowLeaveModal] = (0, react_1.useState)(false);
    const [logoUploadError, setLogoUploadError] = (0, react_1.useState)();
    const { error: configError, config, refetch: refetchEmailConfig } = (0, common_1.useTransactionalEmailConfig)();
    const { control, formState: { isDirty, isValid, errors, isValidating }, handleSubmit, reset, setValue, setError, watch } = (0, react_hook_form_1.useForm)({
        mode: 'onChange',
        defaultValues: DEFAULT_VALUES,
        resolver: (0, zod_1.zodResolver)(global_config_validation_1.formSchema)
    });
    const navigate = (0, react_router_dom_1.useNavigate)();
    (0, hooks_1.useConfirmFormLeave)({ isFormDirty: isDirty });
    const logoImageUrl = watch('logoImageUrl');
    (0, react_1.useEffect)(() => {
        hideServerBanners();
    }, [isValidating]);
    const [createAssetUploadUrl] = (0, client_1.useMutation)(common_1.CREATE_ASSET_UPLOAD_URL, {
        onError(_error) {
            setLogoUploadError('There was an issue uploading the logo image. Please try again.');
        }
    });
    const [updateGlobalConfig, { loading: loadingUpdate }] = (0, client_1.useMutation)(global_config_graphql_1.UPDATE_GLOBAL_EMAIL_CONFIG, {
        onCompleted: () => {
            setShowFormSuccess(true);
            setShowErrorBanner(false);
            // Refetch to update default discard/reset values
            refetchEmailConfig();
        },
        onError(error) {
            var _a;
            setShowErrorBanner(true);
            setShowFormSuccess(false);
            // handle server validation errors
            const hasValidationErrors = Boolean(error.graphQLErrors.length && ((_a = error.graphQLErrors[0].extensions) === null || _a === void 0 ? void 0 : _a.field));
            if (hasValidationErrors) {
                error.graphQLErrors.forEach(({ extensions, message }) => {
                    const fieldPath = extensions.field_path.split('.');
                    const fieldName = fieldPath.length > 1
                        ? `${fieldPath[0]}.${(0, lodash_1.camelCase)(fieldPath[1])}`
                        : (0, lodash_1.camelCase)(fieldPath[0]);
                    setError(fieldName, {
                        type: 'validate',
                        message
                    });
                });
                return;
            }
            // handle all other server errors
            setError('root.serverError', {
                type: 'server',
                message: error.message
            });
        }
    });
    const onSubmit = (data) => {
        setShowErrorBanner(false);
        if (!account) {
            throw new Error('No account selected');
        }
        updateGlobalConfig({
            variables: {
                input: Object.assign(Object.assign({}, data), { accountId: account.id })
            }
        });
    };
    const values = watch();
    (0, react_1.useEffect)(() => {
        resetToConfigDefaults();
    }, [config]);
    function resetToConfigDefaults() {
        if (!config) {
            // Config response is successful but we receive null
            reset(Object.assign(Object.assign({}, DEFAULT_VALUES), { senderName: account === null || account === void 0 ? void 0 : account.name }));
            return;
        }
        // Use available config values if available or set to default
        const formValues = (0, lodash_1.mergeWith)({}, DEFAULT_VALUES, config, (defaultValue, updatedValue) => updatedValue === null ? defaultValue : undefined);
        reset(formValues);
    }
    function handleRemoveImageUrl() {
        setValue('logoImageUrl', '');
    }
    function hideServerBanners() {
        if (showErrorBanner || showFormSuccess) {
            setShowErrorBanner(false);
            setShowFormSuccess(false);
        }
    }
    function handleImageUpload(file) {
        return __awaiter(this, void 0, void 0, function* () {
            const logoErrorMsg = 'There was an issue uploading the logo image. Please try again.';
            hideServerBanners();
            if (!file) {
                return;
            }
            if (!account) {
                throw new Error('No account selected');
            }
            setLogoUploadError(undefined);
            const result = yield createAssetUploadUrl({
                variables: {
                    input: {
                        accountId: account.id,
                        filename: 'logo.png'
                    }
                }
            });
            if (!result.data) {
                setLogoUploadError(logoErrorMsg);
                return;
            }
            const { publicUrl, url, version } = result.data.createTransactionalEmailAssetUploadUrl;
            const uploadResult = yield fetch(url, {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/png',
                    'x-amz-meta-malomo-version': `${version}`
                },
                body: file
            });
            if (!uploadResult.ok) {
                const { status, statusText } = uploadResult;
                setLogoUploadError(`${status} ${statusText}: ${logoErrorMsg}`);
                return;
            }
            setLogoUploadError(undefined);
            setValue('logoImageUrl', publicUrl);
        });
    }
    function handleColorChange(color, name) {
        hideServerBanners();
        setValue(name, color);
    }
    function handlePageBackButtonPressed() {
        if (!isDirty) {
            navigate('/transactional_emails');
            return;
        }
        setShowLeaveModal(true);
    }
    function handleCloseFormLeaveModal() {
        setShowLeaveModal(false);
    }
    function handleSkipFormLeaveModal() {
        navigate('/transactional_emails');
    }
    if (configError) {
        return renderServerErrorBanner();
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.FormLeaveModal, { active: showLeaveModal, onClose: handleCloseFormLeaveModal, onSkipWarning: handleSkipFormLeaveModal }),
        react_1.default.createElement(polaris_1.Form, { noValidate: true, onSubmit: handleSubmit(onSubmit) },
            react_1.default.createElement(layout_1.PolarisPage, { backAction: {
                    onAction: handlePageBackButtonPressed
                }, fullWidth: true, primaryAction: react_1.default.createElement(polaris_1.Button, { disabled: !isValid, id: 'email-global-config-save-button', loading: loadingUpdate, submit: true, variant: 'primary' }, "Save"), secondaryActions: react_1.default.createElement(polaris_1.Button, { id: 'email-global-config-discard-button', onClick: resetToConfigDefaults }, "Discard"), title: global_config_copy_1.GLOBAL_CONFIG_COPY.pageTitle },
                react_1.default.createElement(polaris_1.BlockStack, { gap: '400' },
                    renderBanner(),
                    react_1.default.createElement(polaris_1.Layout, null,
                        react_1.default.createElement(polaris_1.Layout.Section, { variant: 'oneHalf' },
                            react_1.default.createElement(polaris_1.Layout, null,
                                react_1.default.createElement(polaris_1.Layout.Section, null, renderInfoCard()),
                                react_1.default.createElement(polaris_1.Layout.Section, null, renderLogoCard()),
                                react_1.default.createElement(polaris_1.Layout.Section, null, renderSocialCard()),
                                react_1.default.createElement(polaris_1.Layout.Section, null, renderColorPickerCard()))),
                        react_1.default.createElement(polaris_1.Layout.Section, { variant: 'oneHalf' },
                            react_1.default.createElement(common_1.EmailPreview, { config: values, notificationTypeSlug: 'order_confirmation' }))))))));
    // Replaces the page when default config values can't be obtained
    function renderServerErrorBanner() {
        return (react_1.default.createElement(layout_1.PolarisPage, null,
            react_1.default.createElement("div", { id: 'email-global-config-default-values-error' },
                react_1.default.createElement(polaris_1.Banner, { title: 'Cannot load email template configuration.', tone: 'critical' }, errors_copy_1.errorsCopy.fetchError))));
    }
    function renderBanner() {
        var _a;
        if (showErrorBanner && ((_a = errors.root) === null || _a === void 0 ? void 0 : _a.serverError)) {
            return (react_1.default.createElement(polaris_1.Banner, { onDismiss: () => setShowErrorBanner(false), title: errors_copy_1.errorsCopy.fetchError, tone: 'critical' }));
        }
        if (showErrorBanner) {
            return (react_1.default.createElement(polaris_1.Banner, { onDismiss: () => setShowErrorBanner(false), title: 'Error: Please fix field validation errors and then try again.', tone: 'critical' }));
        }
        if (showFormSuccess) {
            return (react_1.default.createElement(polaris_1.Banner, { onDismiss: () => setShowFormSuccess(false), title: 'Email template configuration successfully updated.', tone: 'success' }));
        }
        return undefined;
    }
    function renderInfoCard() {
        return (react_1.default.createElement(polaris_1.Card, null,
            react_1.default.createElement(polaris_1.BlockStack, { gap: '300' },
                react_1.default.createElement(polaris_1.Text, { as: 'h2', variant: 'headingMd' }, "Info"),
                react_1.default.createElement(polaris_1.FormLayout, null,
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'senderName', render: (_a) => {
                            var _b;
                            var _c = _a.field, { value, ref: _ref } = _c, rest = __rest(_c, ["value", "ref"]);
                            return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = errors.senderName) === null || _b === void 0 ? void 0 : _b.message, id: 'email-global-config-sender', inputMode: 'text', label: 'Sender name', type: 'text', value: value ? value : undefined })));
                        } }),
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'replyToEmail', render: (_a) => {
                            var _b;
                            var _c = _a.field, { value, ref: _ref } = _c, rest = __rest(_c, ["value", "ref"]);
                            return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'email', error: (_b = errors.replyToEmail) === null || _b === void 0 ? void 0 : _b.message, id: 'email-global-config-replyto', inputMode: 'email', label: 'Reply-to email address', placeholder: 'reply@example.com', type: 'email', value: value ? value : undefined })));
                        } }),
                    renderAddressFields()))));
    }
    function renderLogoCard() {
        return (react_1.default.createElement(polaris_1.Card, null,
            react_1.default.createElement(polaris_1.BlockStack, { gap: '300' },
                react_1.default.createElement(polaris_1.Text, { as: 'h2', variant: 'headingMd' }, "Logo"),
                react_1.default.createElement(components_1.ImageUpload, { dropZoneHintText: global_config_copy_1.GLOBAL_CONFIG_COPY.imageUploadDropZoneText, existingImageUrl: logoImageUrl, handleImageUpload: handleImageUpload, handleRemoveImageUrl: handleRemoveImageUrl, helpText: global_config_copy_1.GLOBAL_CONFIG_COPY.imageUploadHelpText, maxFileSizeKB: 400, name: 'email-global-config-logo-image-upload', onlyAllowImageTypes: ['image/png'], uploadError: logoUploadError }),
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'logoWidth', render: (_a) => {
                        var _b = _a.field, { ref: _ref } = _b, rest = __rest(_b, ["ref"]);
                        return (react_1.default.createElement(polaris_1.RangeSlider, Object.assign({}, rest, { id: 'email-global-config-logo-size', label: 'Logo size', max: 100, min: 0, output: true, step: 1, suffix: react_1.default.createElement(polaris_1.Box, { paddingInlineStart: '300' },
                                rest.value,
                                "%") })));
                    } }),
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'logoTargetLink', render: (_a) => {
                        var _b;
                        var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                        return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = errors.logoTargetLink) === null || _b === void 0 ? void 0 : _b.message, helpText: global_config_copy_1.GLOBAL_CONFIG_COPY.logoLinkHelpText, id: 'email-global-config-logo-url', label: 'Logo link', placeholder: 'https://example.com', type: 'url', value: rest.value ? rest.value : '' })));
                    } }))));
    }
    function renderAddressFields() {
        const ADDRESS_FIELDS = [
            { name: 'addressLine1', label: 'Address Line 1' },
            { name: 'addressLine2', label: 'Address Line 2' },
            { name: 'city', label: 'City' },
            { name: 'state', label: 'State / Province' },
            { name: 'country', label: 'Country' },
            { name: 'zip', label: 'ZIP' }
        ];
        const addressFields = ADDRESS_FIELDS.reduce((acc, { name, label }) => {
            acc[name] = (react_1.default.createElement(react_hook_form_1.Controller, { control: control, key: `address.${name}-controller`, name: `address.${name}`, render: (_a) => {
                    var _b;
                    var { fieldState } = _a, _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                    return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, id: `email-global-config-address-${name}`, label: label, type: 'text' })));
                } }));
            return acc;
        }, {});
        return (react_1.default.createElement(polaris_1.FormLayout, null,
            addressFields.addressLine1,
            addressFields.addressLine2,
            react_1.default.createElement(polaris_1.FormLayout.Group, null,
                addressFields.city,
                addressFields.state),
            react_1.default.createElement(polaris_1.FormLayout.Group, null,
                addressFields.country,
                addressFields.zip)));
    }
    function renderSocialCard() {
        return (react_1.default.createElement(polaris_1.Card, null,
            react_1.default.createElement(polaris_1.BlockStack, { gap: '300' },
                react_1.default.createElement(polaris_1.Text, { as: 'h2', variant: 'headingMd' }, "Socials"),
                renderSocialList())));
    }
    function renderSocialList() {
        return SOCIAL_DETAILS.map(({ name, label, placeholder }) => {
            return (react_1.default.createElement(react_hook_form_1.Controller, { control: control, key: `socials.${name}-controller`, name: `socials.${name}`, render: (_a) => {
                    var _b;
                    var { fieldState } = _a, _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                    return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, id: `email-global-config-${name}`, label: label, placeholder: placeholder, type: 'url' })));
                } }));
        });
    }
    function renderColorPickerCard() {
        return (react_1.default.createElement(polaris_1.Card, null,
            react_1.default.createElement(polaris_1.BlockStack, { gap: '300' },
                react_1.default.createElement(polaris_1.Text, { as: 'h2', variant: 'headingMd' }, "Colors"),
                renderColorPickerList())));
    }
    function renderColorPickerList() {
        return COLOR_TYPES.map((name) => (react_1.default.createElement(react_hook_form_1.Controller, { control: control, key: `colors.${name}-controller`, name: `colors.${name}`, render: (_a) => {
                var _b = _a.field, { ref: _ref } = _b, rest = __rest(_b, ["ref"]);
                return (react_1.default.createElement(components_1.ColorPickerBox, Object.assign({}, rest, { handleColorChange: handleColorChange, label: exports.COLOR_LABELS[name] })));
            } })));
    }
};
exports.EmailTemplatesGlobalConfig = EmailTemplatesGlobalConfig;
