"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ORDER_DETAILS = exports.GET_SEARCHABLE_ORDERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_SEARCHABLE_ORDERS = (0, client_1.gql) `
  query searchableOrder($input: SearchableOrderInput!) {
    searchableOrder(input: $input) {
      facetCounts
      found
      hits
      outOf
      page
      requestParams
    }
  }
`;
exports.GET_ORDER_DETAILS = (0, client_1.gql) `
  query order($accountId: ID!, $id: ID!) {
    order(accountId: $accountId, id: $id) {
      id
      name
      number
      placedAt
      insertedAt
      customer {
        firstName
        lastName
        email
      }
      shipments {
        id
        arrival
        carrier
        carrierName
        carrierUrl
        estimatedDeliveryDate
        originalEstimatedDeliveryDate
        status
        trackingCode
      }
      issues {
        edges {
          node {
            id
            rule {
              name
            }
            shipment {
              id
            }
            insertedAt
          }
        }
      }
    }
  }
`;
