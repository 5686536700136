"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordResetCopy = void 0;
exports.passwordResetCopy = {
    header: 'Reset Your Password',
    input1: 'New Password',
    input2: 'Confirm New Password',
    cta: 'Reset',
    confirmation: 'Success!',
    prompt: 'Enter your new password',
    heroHeader: 'New in Malomo: Tracking Page Creator',
    heroSubHeader: 'With just two clicks, you can create a new order tracking page that seamlessly integrates with your Shopify theme.',
    errorTitle: 'Password Reset Error'
};
