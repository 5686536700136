"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_USER_COMMUNICATIONS = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_USER_COMMUNICATIONS = (0, client_1.gql) `
  mutation updateCurrentUserCommunications(
    $input: UpdateCurrentUserCommunicationsInput!
  ) {
    updateCurrentUserCommunications(input: $input) {
      user {
        weeklyPerformanceEmail
      }
    }
  }
`;
