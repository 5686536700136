"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTransactionalEmailConfig = void 0;
const client_1 = require("@apollo/client");
const use_transactional_email_config_graphql_1 = require("./use-transactional-email-config.graphql");
const AccountContext_1 = require("contexts/AccountContext");
const useTransactionalEmailConfig = () => {
    const { account } = (0, AccountContext_1.useAccount)();
    const getEmailConfig = (0, client_1.useQuery)(use_transactional_email_config_graphql_1.GET_GLOBAL_EMAIL_CONFIG, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { accountId: account.id },
        skip: !(account === null || account === void 0 ? void 0 : account.id)
    });
    const { error, loading, data, refetch } = getEmailConfig;
    const emailConfig = (data === null || data === void 0 ? void 0 : data.transactionalEmailConfig) || null;
    return {
        error,
        loading,
        config: emailConfig,
        refetch
    };
};
exports.useTransactionalEmailConfig = useTransactionalEmailConfig;
