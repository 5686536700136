"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTrackingPageModal = void 0;
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const react_hook_form_1 = require("react-hook-form");
const lodash_1 = require("lodash");
const polaris_1 = require("@shopify/polaris");
const zod_1 = require("@hookform/resolvers/zod");
const zod_2 = require("zod");
const AccountContext_1 = require("contexts/AccountContext");
const styles_1 = require("./styles");
const graphql_1 = require("components/Dashboard/TrackingPages/graphql");
const DomainDropdown_1 = require("./DomainDropdown");
const ThemeSelect_1 = require("./ThemeSelect");
const Rebuy = __importStar(require("./Rebuy"));
const DEFAULT_FIELD_VALUES = {
    title: 'Track Your Order',
    description: '',
    pageHandle: 'order-tracking',
    baseUrl: '',
    isDefault: false,
    includeRebuy: false
};
const FIELD_LABELS = {
    title: 'Page Title',
    description: 'Page Description',
    baseUrl: 'Store Domain',
    pageHandle: 'Page Suffix',
    isDefault: 'Set as default tracking page URL',
    includeRebuy: 'Add a Rebuy Widget to my page',
    themeId: 'Theme'
};
/* eslint-disable camelcase */
const schema = zod_2.z.object({
    title: zod_2.z
        .string({
        invalid_type_error: `${FIELD_LABELS.title} must be a string`
    })
        .min(1, { message: `${FIELD_LABELS.title} is a required field` }),
    description: zod_2.z
        .string({
        invalid_type_error: `${FIELD_LABELS.description} must be a string`
    })
        .optional(),
    baseUrl: zod_2.z
        .string()
        .min(1, { message: `${FIELD_LABELS.baseUrl} is a required field` })
        .regex(/^(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,63}$/, {
        message: `${FIELD_LABELS.baseUrl} must have a valid format. e.g. example.com`
    }),
    pageHandle: zod_2.z
        .string()
        .min(2, {
        message: `${FIELD_LABELS.pageHandle} must have at least 2 characters`
    })
        .regex(/^[a-z0-9_-]+$/, {
        message: `${FIELD_LABELS.pageHandle} has invalid format. Only lowercase letters, numbers, underscores and dashes are allowed.`
    })
        .regex(/^[a-z0-9][a-z0-9_-]*[a-z0-9]$/, {
        message: `${FIELD_LABELS.pageHandle} cannot start or end with an underscore or dash`
    }),
    isDefault: zod_2.z.boolean({
        required_error: 'isDefault is required',
        invalid_type_error: 'isDefault must be a boolean'
    }),
    includeRebuy: zod_2.z.boolean(),
    themeId: zod_2.z.string()
});
const CreateTrackingPageModal = ({ active, onChangeSuccessAlert, onChangeSuccessTitle, onClose }) => {
    var _a, _b;
    const { account, setAccount } = (0, AccountContext_1.useAccount)();
    const [showRebuy, setShowRebuy] = (0, react_1.useState)(false);
    const { control, watch, setError, formState: { isValid, errors }, handleSubmit, reset, setValue } = (0, react_hook_form_1.useForm)({
        mode: 'onChange',
        defaultValues: DEFAULT_FIELD_VALUES,
        resolver: (0, zod_1.zodResolver)(schema)
    });
    const [publishedTheme, setPublishedTheme] = (0, react_1.useState)();
    const { loading: formDataLoading, data: formData } = (0, client_1.useQuery)(graphql_1.GET_FORM_DATA, {
        skip: !account,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { accountId: account.id },
        onCompleted: (data) => {
            const mainTheme = data.shopifyGetThemes.themes.find(({ role }) => role === 'main');
            setPublishedTheme(mainTheme);
            setValue('themeId', (mainTheme === null || mainTheme === void 0 ? void 0 : mainTheme.id.toString()) || '');
        },
        fetchPolicy: 'no-cache'
    });
    (0, react_1.useEffect)(() => {
        const shopifyDomain = formData === null || formData === void 0 ? void 0 : formData.shopifyGetDomains.myshopifyDomain;
        if (shopifyDomain) {
            const checkRebuy = () => __awaiter(void 0, void 0, void 0, function* () {
                const enabled = yield Rebuy.checkRebuyEnabled(shopifyDomain);
                setShowRebuy(enabled);
            });
            checkRebuy();
        }
        else {
            setShowRebuy(false);
        }
    }, [formData === null || formData === void 0 ? void 0 : formData.shopifyGetDomains.myshopifyDomain]);
    const [createTrackingPage, { loading }] = (0, client_1.useMutation)(graphql_1.CREATE_TRACKING_PAGE, {
        refetchQueries: [
            { query: graphql_1.GET_TRACKING_PAGES, variables: { accountId: account === null || account === void 0 ? void 0 : account.id } },
            'externalTrackingPage'
        ],
        onCompleted: ({ createExternalTrackingPage: { externalTrackingPage } }) => {
            if (account && externalTrackingPage.isDefault) {
                setAccount(Object.assign(Object.assign({}, account), { defaultTrackingPageUrl: externalTrackingPage.url }));
            }
            onChangeSuccessTitle(`'${externalTrackingPage.title}' has been created`);
            onChangeSuccessAlert();
            reset(DEFAULT_FIELD_VALUES);
            onClose();
        },
        onError(error) {
            var _a;
            // handle server validation errors
            const hasValidationErrors = error.graphQLErrors.length && ((_a = error.graphQLErrors[0].extensions) === null || _a === void 0 ? void 0 : _a.field);
            if (hasValidationErrors) {
                error.graphQLErrors.forEach(({ extensions }) => {
                    const { field, message } = extensions;
                    const fieldName = (0, lodash_1.camelCase)(field);
                    setError(fieldName, {
                        type: 'validate',
                        message: `${FIELD_LABELS[fieldName]} ${message}`
                    });
                });
                return;
            }
            // handle all other server errors
            setError('root.serverError', {
                type: 'server',
                message: error.message
            });
        }
    });
    const baseUrl = watch('baseUrl');
    const pageHandle = watch('pageHandle');
    const themeId = watch('themeId');
    const publishedThemeSelected = (themeId === null || themeId === void 0 ? void 0 : themeId.toString()) === (publishedTheme === null || publishedTheme === void 0 ? void 0 : publishedTheme.id.toString());
    // only allow to set tracking page as default if the theme is published
    (0, react_1.useEffect)(() => {
        if (!publishedThemeSelected) {
            setValue('isDefault', false);
        }
    }, [publishedThemeSelected]);
    const onSubmit = handleSubmit((data) => {
        if (!account) {
            throw new Error('No account selected');
        }
        const { includeRebuy } = data, rest = __rest(data, ["includeRebuy"]);
        const additionalSections = {
            order: [],
            sections: []
        };
        if (includeRebuy) {
            const section = Rebuy.sectionSpecification();
            additionalSections.order.push('rebuy');
            additionalSections.sections.push(section);
        }
        const transformedData = Object.assign(Object.assign({}, rest), { baseUrl: `https://${rest.baseUrl}`, themeId: parseInt(rest.themeId, 10), additionalSections });
        createTrackingPage({
            variables: {
                input: Object.assign(Object.assign({}, transformedData), { accountId: account.id })
            }
        });
    });
    return (react_1.default.createElement(polaris_1.Modal, { instant: true, loading: formDataLoading, onClose: onClose, open: active, primaryAction: {
            content: 'Create Tracking Page',
            loading,
            onAction: onSubmit,
            disabled: !isValid
        }, title: 'Create a New Tracking Page' },
        react_1.default.createElement(polaris_1.Modal.Section, null,
            react_1.default.createElement("form", null,
                react_1.default.createElement(polaris_1.FormLayout, null,
                    react_1.default.createElement("p", null, "Add a few key details below to create your tracking page."),
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'title', render: (_a) => {
                            var _b;
                            var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                            return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = errors.title) === null || _b === void 0 ? void 0 : _b.message, label: FIELD_LABELS.title })));
                        } }),
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'description', render: (_a) => {
                            var _b;
                            var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                            return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = errors.description) === null || _b === void 0 ? void 0 : _b.message, helpText: 'This description is for your notes only. It will not be visible on your Shopify page.', label: `${FIELD_LABELS.description} (optional)`, multiline: 3 })));
                        } }),
                    formData && (react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'baseUrl', render: (_a) => {
                            var _b;
                            var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                            return (react_1.default.createElement(DomainDropdown_1.DomainDropdown, Object.assign({}, rest, { domains: formData.shopifyGetDomains, error: (_b = errors.baseUrl) === null || _b === void 0 ? void 0 : _b.message, label: FIELD_LABELS.baseUrl })));
                        } })),
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'pageHandle', render: (_a) => {
                            var _b;
                            var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                            return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = errors.pageHandle) === null || _b === void 0 ? void 0 : _b.message, label: FIELD_LABELS.pageHandle })));
                        } }),
                    baseUrl && pageHandle && !errors.baseUrl && !errors.pageHandle && (react_1.default.createElement("div", null,
                        react_1.default.createElement("p", null, "URL Preview"),
                        react_1.default.createElement(styles_1.Preview, null, `https://${baseUrl}/pages/${pageHandle}`))),
                    formData && (react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'themeId', render: (_a) => {
                            var _b;
                            var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                            return (react_1.default.createElement(ThemeSelect_1.ThemeSelect, Object.assign({}, rest, { error: (_b = errors.themeId) === null || _b === void 0 ? void 0 : _b.message, label: FIELD_LABELS.themeId, themes: formData.shopifyGetThemes.themes })));
                        } })),
                    react_1.default.createElement(polaris_1.BlockStack, { gap: '100' },
                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'isDefault', render: (_a) => {
                                var _b = _a.field, { ref: _ref, value } = _b, rest = __rest(_b, ["ref", "value"]);
                                return (react_1.default.createElement(polaris_1.Checkbox, Object.assign({ id: 'trackingPageModalDefaultCheckbox' }, rest, { checked: value, disabled: !publishedThemeSelected, label: FIELD_LABELS.isDefault })));
                            } }),
                        react_1.default.createElement(polaris_1.InlineStack, { gap: '100' },
                            react_1.default.createElement(polaris_1.Text, { as: 'p', tone: 'subdued' },
                                "Important: If you are currently sending traffic to another tracking page from a connected app, please review this article before changing the default URL.",
                                ' ',
                                react_1.default.createElement(polaris_1.Link, { external: true, url: 'https://help.gomalomo.com/csc/linking-to-your-tracking-page' }, "Learn more \u203A")))),
                    showRebuy && (react_1.default.createElement(polaris_1.InlineStack, { blockAlign: 'center', gap: '200' },
                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'includeRebuy', render: (_a) => {
                                var _b = _a.field, { ref: _ref, value } = _b, rest = __rest(_b, ["ref", "value"]);
                                return (react_1.default.createElement(polaris_1.Checkbox, Object.assign({}, rest, { checked: value, label: FIELD_LABELS.includeRebuy })));
                            } }),
                        react_1.default.createElement(polaris_1.Link, { external: true, url: 'https://help.gomalomo.com/csc/build-with-the-malomo-tracking-page-creator#BuildYourTrackingPagewiththeMalomoTrackingPageCreator-Instructions' }, "Learn more \u203A"))),
                    ((_a = errors.root) === null || _a === void 0 ? void 0 : _a.serverError) && (react_1.default.createElement(polaris_1.InlineError, { fieldID: 'serverError', message: (_b = errors.root.serverError.message) !== null && _b !== void 0 ? _b : 'Oops! Something went wrong.' })))))));
};
exports.CreateTrackingPageModal = CreateTrackingPageModal;
