"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_GLOBAL_EMAIL_CONFIG = void 0;
const client_1 = require("@apollo/client");
exports.GET_GLOBAL_EMAIL_CONFIG = (0, client_1.gql) `
  query TransactionalEmailConfig($accountId: ID!) {
    transactionalEmailConfig(accountId: $accountId) {
      id
      senderName
      replyToEmail
      logoImageUrl
      logoTargetLink
      logoWidth
      socials {
        facebook
        instagram
        x
      }
      colors {
        accent
        background
        buttonText
        text
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zip
        country
      }
    }
  }
`;
