"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_EMAIL_TEMPLATE = exports.GET_EMAIL_TEMPLATE = exports.CREATE_ASSET_UPLOAD_URL = void 0;
const client_1 = require("@apollo/client");
const common_1 = require("@/emails/common");
Object.defineProperty(exports, "CREATE_ASSET_UPLOAD_URL", { enumerable: true, get: function () { return common_1.CREATE_ASSET_UPLOAD_URL; } });
const GET_EMAIL_TEMPLATE = (0, client_1.gql) `
  query TransactionalEmailTemplate(
    $accountId: ID!
    $notificationTypeSlug: String!
  ) {
    transactionalEmailTemplate(
      accountId: $accountId
      notificationTypeSlug: $notificationTypeSlug
    ) {
      id
      active
      body
      footer
      heading
      imageBannerLink
      imageBannerUrl
      subject
      notificationType {
        id
        slug
      }
    }
  }
`;
exports.GET_EMAIL_TEMPLATE = GET_EMAIL_TEMPLATE;
const UPDATE_EMAIL_TEMPLATE = (0, client_1.gql) `
  mutation UpdateTransactionalEmailTemplate(
    $input: UpdateTransactionalEmailTemplateInput!
  ) {
    updateTransactionalEmailTemplate(input: $input) {
      transactionalEmailTemplate {
        id
        active
        body
        footer
        heading
        image_banner_link
        image_banner_url
        subject
        notificationType {
          id
          slug
        }
      }
    }
  }
`;
exports.UPDATE_EMAIL_TEMPLATE = UPDATE_EMAIL_TEMPLATE;
