"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passesFilterCheck = exports.formatOptionalField = exports.formatOptionalDate = exports.formatCarrierName = exports.formatCustomerName = exports.formatDate = exports.findLatestOrderIssue = void 0;
const lodash_1 = require("lodash");
function formatCustomerName(customer) {
    if (!customer) {
        return 'N/A';
    }
    return [customer.firstName, customer.lastName].filter((x) => !!x).join(' ');
}
exports.formatCustomerName = formatCustomerName;
function formatCarrierName(name) {
    return name ? (0, lodash_1.startCase)(name) : '';
}
exports.formatCarrierName = formatCarrierName;
function formatDate(timestamp) {
    const monthDay = new Date(timestamp).toLocaleDateString([], {
        month: 'short',
        day: '2-digit'
    });
    let time = new Date(timestamp).toLocaleTimeString([], {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric'
    });
    // Remove space in front of AM/PM
    time = time.replace(/\s?(AM|PM)$/, '$1');
    return `${monthDay} at ${time}`;
}
exports.formatDate = formatDate;
function formatOptionalDate(timestamp, nullReturnValue = '-') {
    if (!timestamp) {
        return nullReturnValue;
    }
    return formatDate(timestamp);
}
exports.formatOptionalDate = formatOptionalDate;
function formatOptionalField(value, nullReturnValue = 'N/A') {
    return value ? value : nullReturnValue;
}
exports.formatOptionalField = formatOptionalField;
function passesFilterCheck(filterList, valueToCheck) {
    return (filterList.length === 0 ||
        (!!valueToCheck && filterList.includes(valueToCheck)));
}
exports.passesFilterCheck = passesFilterCheck;
function findLatestOrderIssue(issues) {
    if (!issues || issues.length === 0) {
        return null;
    }
    return issues.reduce((latest, current) => {
        return current.inserted_at > latest.inserted_at ? current : latest;
    }, issues[0]);
}
exports.findLatestOrderIssue = findLatestOrderIssue;
