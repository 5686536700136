"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const standardPageCount = 12;
const usePaginatedSearch = (fetchMore, key, data, pageCount = standardPageCount, extraVariables = {}) => {
    const [searched, setSearched] = (0, react_1.useState)(false);
    const [searchQuery, setSearchQuery] = (0, react_1.useState)('');
    const handleSearch = (0, react_1.useCallback)((query) => {
        setSearched(true);
        setSearchQuery(query);
        let searchVariables = {};
        if (query) {
            searchVariables = {
                first: pageCount,
                q: query
            };
        }
        else {
            searchVariables = {
                first: pageCount
            };
        }
        fetchMore({
            variables: Object.assign(Object.assign({}, searchVariables), extraVariables),
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult[key].edges;
                const pageInfo = fetchMoreResult[key].pageInfo;
                return {
                    [key]: {
                        __typename: previousResult[key].__typename,
                        edges: [...newEdges],
                        pageInfo
                    }
                };
            }
        });
    }, [pageCount]);
    function handleLoadNext() {
        if (data && data[key].pageInfo.hasNextPage) {
            let searchVariables = {};
            if (searchQuery) {
                searchVariables = {
                    after: data[key].pageInfo.endCursor,
                    first: pageCount,
                    q: searchQuery
                };
            }
            else {
                searchVariables = {
                    after: data[key].pageInfo.endCursor,
                    first: pageCount
                };
            }
            fetchMore({
                variables: searchVariables,
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult[key].edges;
                    const pageInfo = fetchMoreResult[key].pageInfo;
                    return newEdges.length
                        ? {
                            [key]: {
                                __typename: previousResult[key].__typename,
                                edges: [...previousResult[key].edges, ...newEdges],
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            });
        }
    }
    return {
        searched,
        searchQuery,
        handleSearch,
        handleLoadNext
    };
};
exports.default = usePaginatedSearch;
