"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInfiniteScroll = void 0;
const react_1 = require("react");
const lodash_1 = require("lodash");
const useInfiniteScroll = ({ hasNext, loadNext }) => {
    (0, react_1.useEffect)(() => {
        const dashboardContainer = document.getElementById('dashboard-container');
        const handleScroll = (0, lodash_1.debounce)(() => {
            // at this point we know this element exists because this function was
            // invoked from an event listener on the element
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const el = dashboardContainer;
            const reachedBottom = el.scrollTop > el.scrollHeight - el.offsetHeight - 50;
            if (reachedBottom && hasNext) {
                loadNext();
            }
        }, 200);
        dashboardContainer === null || dashboardContainer === void 0 ? void 0 : dashboardContainer.addEventListener('scroll', handleScroll);
        return () => {
            dashboardContainer === null || dashboardContainer === void 0 ? void 0 : dashboardContainer.removeEventListener('scroll', handleScroll);
        };
    }, [hasNext, loadNext]);
};
exports.useInfiniteScroll = useInfiniteScroll;
