"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_ASSET_UPLOAD_URL = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_ASSET_UPLOAD_URL = (0, client_1.gql) `
  mutation CreateTransactionalEmailAssetUploadUrl(
    $input: CreateTransactionalEmailAssetUploadUrlInput!
  ) {
    createTransactionalEmailAssetUploadUrl(input: $input) {
      publicUrl
      url
      version
    }
  }
`;
