"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLOR_TYPES = exports.COLOR_LABELS = exports.GLOBAL_CONFIG_COPY = void 0;
exports.GLOBAL_CONFIG_COPY = {
    pageTitle: 'Customize email templates',
    imageUploadHelpText: "Click 'Save' to view your image in the preview. If no logo is uploaded, then the logo section won't show up.",
    imageUploadDropZoneText: 'Accepts .png / max file size: 200KB',
    logoLinkHelpText: "When a customer clicks on the logo, they'll be redirected to this URL.",
    urlValidationErrorMsg: "Please provide a valid url that starts with 'http://' or 'https://' and includes a domain extension such as '.com'."
};
exports.COLOR_LABELS = {
    accentColorHex: 'Accent color',
    textColorHex: 'Text color',
    buttonTextColorHex: 'Button text color',
    backgroundColorHex: 'Background color'
};
exports.COLOR_TYPES = Object.keys(exports.COLOR_LABELS);
