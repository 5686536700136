"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_FORM_DATA = exports.GET_THEMES = exports.CHECK_SHOPIFY_SCOPES = exports.DELETE_TRACKING_PAGE = exports.CREATE_TRACKING_PAGE = exports.GET_TRACKING_PAGES = void 0;
const client_1 = require("@apollo/client");
const TRACKING_PAGE_FIELDS = (0, client_1.gql) `
  fragment TrackingPageFields on ExternalTrackingPage {
    baseUrl
    description
    id
    insertedAt
    isDefault
    pageHandle
    shopifyEditUrl
    themeId
    title
    url
  }
`;
exports.GET_TRACKING_PAGES = (0, client_1.gql) `
  ${TRACKING_PAGE_FIELDS}
  query externalTrackingPage($accountId: ID!) {
    externalTrackingPage(accountId: $accountId) {
      ...TrackingPageFields
    }
  }
`;
exports.CREATE_TRACKING_PAGE = (0, client_1.gql) `
  ${TRACKING_PAGE_FIELDS}
  mutation createExternalTrackingPage(
    $input: CreateExternalTrackingPageInput!
  ) {
    createExternalTrackingPage(input: $input) {
      externalTrackingPage {
        ...TrackingPageFields
      }
    }
  }
`;
exports.DELETE_TRACKING_PAGE = (0, client_1.gql) `
  mutation deleteExternalTrackingPage(
    $input: DeleteExternalTrackingPageInput!
  ) {
    deleteExternalTrackingPage(input: $input) {
      success
    }
  }
`;
exports.CHECK_SHOPIFY_SCOPES = (0, client_1.gql) `
  query ShopifyCheckScopes($input: ShopifyCheckScopesInput!) {
    shopifyCheckScopes(input: $input) {
      hasRequiredScopes
      reauthorizeUrl
    }
  }
`;
const THEME_FIELDS_FRAGMENT = (0, client_1.gql) `
  fragment ThemeFields on ShopifyTheme {
    id
    name
    role
  }
`;
exports.GET_THEMES = (0, client_1.gql) `
  ${THEME_FIELDS_FRAGMENT}
  query GetShopifyThemes($accountId: ID!) {
    shopifyGetThemes(input: { accountId: $accountId }) {
      themes {
        ...ThemeFields
      }
    }
  }
`;
exports.GET_FORM_DATA = (0, client_1.gql) `
  ${THEME_FIELDS_FRAGMENT}
  query GetTrackingPageFormData($accountId: ID!) {
    shopifyGetDomains(input: { accountId: $accountId }) {
      domain
      myshopifyDomain
    }
    shopifyGetThemes(input: { accountId: $accountId }) {
      themes {
        ...ThemeFields
      }
    }
    shopifyGetThemes(input: { accountId: $accountId }) {
      themes {
        id
        name
        role
      }
    }
  }
`;
