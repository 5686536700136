"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorPickerBox = void 0;
const react_1 = __importStar(require("react"));
const polaris_1 = require("@shopify/polaris");
function hexToHSB(hexColor) {
    return (0, polaris_1.rgbToHsb)((0, polaris_1.hexToRgb)(hexColor));
}
function isValidHex(hexColor) {
    // Must start with '#', followed by 3 or 6 hex letters/numbers
    const validHexColorRegex = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
    return validHexColorRegex.test(hexColor);
}
const ColorPickerBox = ({ handleColorChange, label, name, value }) => {
    const [colorPopoverActive, setColorPopoverActive] = (0, react_1.useState)(false);
    const [colorHex, setColorHex] = (0, react_1.useState)(value);
    const [colorBlockHex, setColorBlockHex] = (0, react_1.useState)(value);
    const [colorHSB, setColorHSB] = (0, react_1.useState)(hexToHSB(colorHex));
    // Strip off '.colors' prefix for element ID to avoid selector issues
    const [_prefix, nameWithoutPrefix] = name.split('.');
    (0, react_1.useEffect)(() => {
        // When component is already rendered and a new value is passed in, update colors
        handleColorTyped(value);
    }, [value]);
    function handleColorPicked(hsbValue) {
        const updatedHexValue = (0, polaris_1.hsbToHex)(hsbValue);
        // Picker bugs out if it receives an invalid hex value so avoid accepting them
        if (updatedHexValue !== colorHex && isValidHex(updatedHexValue)) {
            setColorHSB(hsbValue);
            setColorHex(updatedHexValue);
            setColorBlockHex(updatedHexValue);
            handleColorChange(updatedHexValue, name);
        }
    }
    function handleColorTyped(hexValue) {
        const hex = hexValue.toLowerCase();
        setColorHex(hex);
        if (isValidHex(hex)) {
            /** Only change these when the updated hex value is valid, otherwise the color
             picker has some really weird behavior around the appearance of picking a new
             color but not actually picking a new color. **/
            setColorHSB(hexToHSB(hex));
            setColorBlockHex(hex);
            handleColorChange(hex, name);
        }
    }
    function handlePopoverToggle() {
        setColorPopoverActive((active) => !active);
    }
    function handleColorSubmit(e) {
        // Prevent pressing enter from submitting the entire form tied to the color picker
        e.stopPropagation();
        handlePopoverToggle();
    }
    return (react_1.default.createElement(polaris_1.InlineStack, { align: 'start', blockAlign: 'center', gap: '300' },
        react_1.default.createElement(polaris_1.Popover, { activator: react_1.default.createElement("div", { id: `${nameWithoutPrefix}-color-picker-preview-box`, onClick: handlePopoverToggle, style: {
                    width: '50px',
                    height: '30px',
                    backgroundColor: colorBlockHex,
                    borderRadius: 'var(--p-border-radius-200)',
                    borderColor: 'var(--p-color-border-hover)',
                    borderWidth: 'var(--p-border-width-0165)',
                    borderStyle: 'solid'
                } }), active: colorPopoverActive, ariaHaspopup: false, fixed: false, onClose: handlePopoverToggle, preferredAlignment: 'right', preferredPosition: 'above', sectioned: true },
            react_1.default.createElement(polaris_1.Popover.Pane, null,
                react_1.default.createElement(polaris_1.Card, null,
                    react_1.default.createElement(polaris_1.BlockStack, { gap: '400' },
                        react_1.default.createElement(polaris_1.ColorPicker, { color: colorHSB, onChange: handleColorPicked }),
                        react_1.default.createElement(polaris_1.Form, { onSubmit: handleColorSubmit },
                            react_1.default.createElement(polaris_1.TextField, { autoComplete: 'off', id: `${nameWithoutPrefix}-color-picker-input-field`, label: 'Picked Color', labelHidden: true, onChange: handleColorTyped, type: 'text', value: colorHex })))))),
        react_1.default.createElement(polaris_1.Text, { as: 'p', id: `${nameWithoutPrefix}-color-picker-input-label`, variant: 'headingSm' }, label)));
};
exports.ColorPickerBox = ColorPickerBox;
