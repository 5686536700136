"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectAccount = exports.GET_EMBEDDED_DASHBOARD = exports.UPDATE_USER_PASSWORD = exports.UPDATE_USER_EMAIL = exports.UPDATE_USER_NAME = exports.GET_ALL_PAGES = exports.GET_SHIPMENTS = exports.GET_BILLING_PAGE = exports.GET_SHIPMENT_REPORT = exports.GET_CUSTOMER_ORDERS = exports.GET_USER = exports.GET_API_KEYS = exports.DELETE_API_KEY = exports.CREATE_API_KEY = exports.GET_ACCOUNTS = exports.PERFORM_PASSWORD_RESET = exports.REQUEST_PASSWORD_RESET = exports.LOGIN_USER = exports.IS_LOGGED_IN = exports.REGISTER_USER = exports.typeDefs = void 0;
const client_1 = require("@apollo/client");
exports.typeDefs = (0, client_1.gql) `
  extend type Query {
    isLoggedIn: Boolean!
  }
`;
exports.REGISTER_USER = (0, client_1.gql) `
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      ok
    }
  }
`;
exports.IS_LOGGED_IN = (0, client_1.gql) `
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;
exports.LOGIN_USER = (0, client_1.gql) `
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      session {
        token
      }
    }
  }
`;
exports.REQUEST_PASSWORD_RESET = (0, client_1.gql) `
  mutation RequestPasswordInput($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      ok
    }
  }
`;
exports.PERFORM_PASSWORD_RESET = (0, client_1.gql) `
  mutation PerformPasswordReset($input: PerformPasswordResetInput!) {
    performPasswordReset(input: $input) {
      user {
        email
      }
    }
  }
`;
exports.GET_ACCOUNTS = (0, client_1.gql) `
  query accounts(
    $q: String
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    accounts(
      q: $q
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      edges {
        node {
          defaultTrackingPageUrl
          id
          name
          primaryHostedDomain {
            token
            id
          }
          hostedDomains {
            edges {
              node {
                token
                id
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
exports.CREATE_API_KEY = (0, client_1.gql) `
  mutation createStandardKey($input: CreateStandardKeyInput!) {
    createStandardKey(input: $input) {
      standardKey {
        id
        name
        token
        type
        insertedAt
      }
    }
  }
`;
exports.DELETE_API_KEY = (0, client_1.gql) `
  mutation DeleteStandardKey($input: DeleteStandardKeyInput!) {
    deleteStandardKey(input: $input) {
      ok
    }
  }
`;
exports.GET_API_KEYS = (0, client_1.gql) `
  query standardKeys($accountId: ID!) {
    standardKeys(accountId: $accountId) {
      edges {
        node {
          id
          name
          token
          type
          insertedAt
        }
      }
    }
  }
`;
exports.GET_USER = (0, client_1.gql) `
  query currentUser {
    currentUser {
      id
      name
      email
      admin
      weeklyPerformanceEmail
    }
  }
`;
exports.GET_CUSTOMER_ORDERS = (0, client_1.gql) `
  query customer($accountId: ID!, $id: ID!) {
    customer(accountId: $accountId, id: $id) {
      orders(first: 20) {
        edges {
          node {
            insertedAt
            number
            shipments {
              id
              arrival
              trackingCode
              carrier
              carrierName
              status
              estimatedDeliveryDate
              originalEstimatedDeliveryDate
            }
            issues {
              edges {
                node {
                  id
                  insertedAt
                  rule {
                    name
                  }
                  shipment {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
exports.GET_SHIPMENT_REPORT = (0, client_1.gql) `
  query shipmentReport(
    $accountId: ID!
    $endDate: Date!
    $startDate: Date!
    $arrival: Arrival
    $carrier: Carrier
  ) {
    shipmentReport(
      accountId: $accountId
      endDate: $endDate
      startDate: $startDate
      arrival: $arrival
      carrier: $carrier
    ) {
      avgFulfillToDelivery
      avgPlacedToDelivery
      avgPlacedToFulfill
      totalCount
      totalEarlyCount
      totalLateCount
      totalOnTimeCount
    }
  }
`;
exports.GET_BILLING_PAGE = (0, client_1.gql) `
  query billingPage($accountId: ID!) {
    billingPage(accountId: $accountId) {
      url
    }
  }
`;
exports.GET_SHIPMENTS = (0, client_1.gql) `
  query shipments(
    $accountId: ID!
    $endDate: Date!
    $startDate: Date!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $arrival: Arrival
    $carrier: Carrier
    $q: String
  ) {
    shipments(
      accountId: $accountId
      endDate: $endDate
      startDate: $startDate
      after: $after
      before: $before
      first: $first
      last: $last
      arrival: $arrival
      carrier: $carrier
      q: $q
    ) {
      edges {
        node {
          carrierName
          estimatedDeliveryDate
          originalEstimatedDeliveryDate
          deliveredAt
          arrival
          order {
            fulfilledAt
            number
            placedAt
          }
          status
          trackingCode
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
exports.GET_ALL_PAGES = (0, client_1.gql) `
  query trackingPages(
    $accountId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    trackingPages(
      accountId: $accountId
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      edges {
        node {
          archivedAt
          deletedAt
          hostedDomain {
            primary
            token
          }
          id
          insertedAt
          name
          slug
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
exports.UPDATE_USER_NAME = (0, client_1.gql) `
  mutation updateCurrentUser($input: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      user {
        name
      }
    }
  }
`;
exports.UPDATE_USER_EMAIL = (0, client_1.gql) `
  mutation updateCurrentUserEmail($input: UpdateCurrentUserEmailInput!) {
    updateCurrentUserEmail(input: $input) {
      user {
        email
      }
    }
  }
`;
exports.UPDATE_USER_PASSWORD = (0, client_1.gql) `
  mutation updateCurrentUserPassword($input: UpdateCurrentUserPasswordInput!) {
    updateCurrentUserPassword(input: $input) {
      user {
        name
      }
    }
  }
`;
exports.GET_EMBEDDED_DASHBOARD = (0, client_1.gql) `
  query embeddedDashboard($id: String!, $accountId: ID!) {
    embeddedDashboard(id: $id, accountId: $accountId) {
      url
    }
  }
`;
function SelectAccount(accountName) {
    localStorage.setItem('account', accountName);
}
exports.SelectAccount = SelectAccount;
