"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConfirmFormLeave = void 0;
const react_1 = require("react");
// Hook that monitors for browser control UI reload or back/forward button usage
// Shows dialog when form is 'dirty' and navigation attempt is made
// Note: This only handles browser UI / window.onbeforeunload, not React navigation
// TODO: Update to data router and useBlocker to have full design control
const useConfirmFormLeave = ({ isFormDirty }) => {
    (0, react_1.useEffect)(() => {
        if (!isFormDirty) {
            return;
        }
        const onBeforeUnload = (e) => {
            e.preventDefault();
            // Chrome fix, has type warning issues but is required
            e.returnValue = true;
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [isFormDirty]);
};
exports.useConfirmFormLeave = useConfirmFormLeave;
