"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScanEventInfo = void 0;
const react_1 = __importStar(require("react"));
const polaris_1 = require("@shopify/polaris");
const utils_1 = require("components/Dashboard/OrderDetails/utils");
const styles_1 = require("./ScanEventModal/styles");
const polaris_icons_1 = require("@shopify/polaris-icons");
function locationAvailable(location) {
    return Boolean(location.city || location.state || location.country);
}
const ScanEventInfo = ({ scanEvent }) => {
    const scannedAt = (0, react_1.useMemo)(() => {
        if (!scanEvent.scannedAt) {
            return null;
        }
        return (0, utils_1.formatScanEventDate)(scanEvent.scannedAt);
    }, [scanEvent.scannedAt]);
    const locationStr = (0, react_1.useMemo)(() => {
        if (!scanEvent.location || !locationAvailable(scanEvent.location)) {
            return null;
        }
        return (0, utils_1.formatScanEventLocation)(scanEvent.location);
    }, [scanEvent.location]);
    return (react_1.default.createElement("div", { role: 'row' },
        react_1.default.createElement(polaris_1.BlockStack, { gap: '100' },
            scannedAt && (react_1.default.createElement("div", { role: 'cell' },
                react_1.default.createElement(polaris_1.Text, { as: 'p' }, scannedAt))),
            react_1.default.createElement("div", { role: 'cell' },
                react_1.default.createElement(polaris_1.Text, { as: 'span', tone: 'subdued' }, scanEvent.message || scanEvent.description || 'Pending')),
            locationStr && (react_1.default.createElement("div", { role: 'cell' },
                react_1.default.createElement(polaris_1.InlineStack, { gap: '100' },
                    react_1.default.createElement(styles_1.StyledIconContainer, null,
                        react_1.default.createElement(polaris_1.Icon, { source: polaris_icons_1.LocationIcon, tone: 'subdued' })),
                    react_1.default.createElement(polaris_1.Text, { as: 'p' }, locationStr)))))));
};
exports.ScanEventInfo = ScanEventInfo;
