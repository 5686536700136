"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_SETUP_INTENT = void 0;
const client_1 = require("@apollo/client");
const SETUP_INTENT_FIELDS = (0, client_1.gql) `
  fragment SetupIntentFields on SetupIntent {
    id
    clientSecret
    status
  }
`;
exports.CREATE_SETUP_INTENT = (0, client_1.gql) `
  ${SETUP_INTENT_FIELDS}
  mutation CreateSetupIntent($input: CreateSetupIntentInput!) {
    createSetupIntent(input: $input) {
      setupIntent {
        ...SetupIntentFields
      }
    }
  }
`;
