"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UninstallAppForAdminUserModal = void 0;
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const zod_1 = require("@hookform/resolvers/zod");
const z = __importStar(require("zod"));
const polaris_1 = require("@shopify/polaris");
const AccountContext_1 = require("contexts/AccountContext");
const DEFAULT_FIELD_VALUES = {
    accountName: ''
};
const schema = z.object({
    accountName: z.string().min(1, { message: 'Required' })
});
const UninstallAppForAdminUserModal = ({ active, selectedApp, onClose, onUninstall }) => {
    const { account } = (0, AccountContext_1.useAccount)();
    const { control, watch, formState: { isValid }, handleSubmit, reset } = (0, react_hook_form_1.useForm)({
        mode: 'onChange',
        defaultValues: DEFAULT_FIELD_VALUES,
        resolver: (0, zod_1.zodResolver)(schema)
    });
    const accountName = watch('accountName');
    const isFormValid = accountName && accountName === (account === null || account === void 0 ? void 0 : account.name) && isValid;
    const onCloseModal = (0, react_1.useCallback)(() => {
        reset(DEFAULT_FIELD_VALUES);
        onClose();
    }, []);
    const onSubmit = handleSubmit(() => {
        if (!selectedApp || !(selectedApp === null || selectedApp === void 0 ? void 0 : selectedApp.clientId)) {
            throw new Error('No app selected');
        }
        if (!account) {
            throw new Error('No account selected');
        }
        onUninstall(selectedApp.clientId);
        reset(DEFAULT_FIELD_VALUES);
        onClose();
    });
    return (react_1.default.createElement(polaris_1.Modal, { onClose: onCloseModal, open: active, primaryAction: {
            content: 'Uninstall',
            disabled: !isFormValid,
            onAction: onSubmit
        }, secondaryActions: [
            {
                content: 'Cancel',
                onAction: onCloseModal
            }
        ], title: 'Uninstall App' },
        react_1.default.createElement(polaris_1.Modal.Section, null,
            react_1.default.createElement(polaris_1.BlockStack, { gap: '500' },
                react_1.default.createElement(polaris_1.Text, { as: 'p' },
                    "Are you sure you want to uninstall",
                    ' ',
                    react_1.default.createElement(polaris_1.Text, { as: 'span', fontWeight: 'bold' }, selectedApp === null || selectedApp === void 0 ? void 0 : selectedApp.name),
                    ' ',
                    "for",
                    ' ',
                    react_1.default.createElement(polaris_1.Text, { as: 'span', fontWeight: 'bold' }, account === null || account === void 0 ? void 0 : account.name),
                    "?"),
                react_1.default.createElement("form", null,
                    react_1.default.createElement("fieldset", null,
                        react_1.default.createElement("legend", null, "Please type the account name to confirm"),
                        react_1.default.createElement(polaris_1.FormLayout, null,
                            react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'accountName', render: (_a) => {
                                    var _b = _a.field, { ref: _ref } = _b, rest = __rest(_b, ["ref"]);
                                    return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', label: 'Account name', labelHidden: true })));
                                } }))))))));
};
exports.UninstallAppForAdminUserModal = UninstallAppForAdminUserModal;
