"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_CARRIERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_CARRIERS = (0, client_1.gql) `
  query carriers($accountId: ID!) {
    carriers(accountId: $accountId) {
      code
      name
    }
  }
`;
