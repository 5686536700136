"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginCopy = void 0;
exports.loginCopy = {
    header: 'Login to Malomo',
    input1: 'Email Address',
    input2: 'Password',
    cta: 'Log in',
    linkPreface: 'Want an account?',
    linkPrompt: 'Contact Us.',
    linkHref: 'mailto:help@gomalomo.com?subject=Help%20me%20create%20an%20account',
    heroHeader: 'New in Malomo: Tracking Page Creator',
    heroSubHeader: 'With just two clicks, you can create a new order tracking page that seamlessly integrates with your Shopify theme.',
    modalHeader: 'Choose an Account',
    forgotPassword: 'Forgot your password?',
    forgotPasswordHref: '/password_recovery',
    errorTitle: 'Login Error'
};
