"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadS3File = void 0;
function uploadS3File(url, file, version, setProgress) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            const xhr = new XMLHttpRequest();
            xhr.upload.onprogress = (progress) => {
                const progressPercent = Math.floor((progress.loaded / progress.total) * 100);
                setProgress(progressPercent);
            };
            xhr.upload.onload = () => {
                resolve(true);
            };
            xhr.upload.onerror = () => {
                reject(false);
            };
            xhr.open('PUT', url);
            xhr.setRequestHeader('Content-Type', 'application/zip');
            xhr.setRequestHeader('x-amz-meta-malomo-version', version);
            xhr.send(formData);
        });
    });
}
exports.uploadS3File = uploadS3File;
