"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_GLOBAL_EMAIL_CONFIG = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_GLOBAL_EMAIL_CONFIG = (0, client_1.gql) `
  mutation UpdateTransactionalEmailConfig(
    $input: UpdateTransactionalEmailConfigInput!
  ) {
    updateTransactionalEmailConfig(input: $input) {
      transactionalEmailConfig {
        id
        senderName
        replyToEmail
        logoImageUrl
        logoWidth
        logoTargetLink
        socials {
          facebook
          instagram
          x
        }
        colors {
          accent
          background
          buttonText
          text
        }
        address {
          addressLine1
          addressLine2
          city
          state
          country
          zip
        }
      }
    }
  }
`;
