"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectToQueryString = void 0;
const lodash_1 = require("lodash");
/**
 * Takes a deeply nested object and converts it to a Phoenix compatible query
 * string, while maintaining its structure
 *
 * @param obj - deeply nested object to be serialized
 * @returns Phoenix compatible query string
 */
const objectToQueryString = (obj) => {
    const params = flattenNestedObject(obj);
    return new URLSearchParams(params).toString();
};
exports.objectToQueryString = objectToQueryString;
/**
 * Flatten a deeply nested object into a Phoenix query string syntax object
 *
 * Does not create the actual query string, that step is to be done outside this
 * function
 *
 * @param obj - object to be flattened
 * @param prefix - (optional) prefix to nest object elements under, used by recursion
 * @returns flattened object matching the Phoenix query string convention
 */
function flattenNestedObject(obj, prefix = '') {
    return Object.keys(obj).reduce((acc, key) => {
        const fullPath = prefix
            ? `${prefix}[${elixirSnakeCase(key)}]`
            : elixirSnakeCase(key);
        const curr = obj[key];
        if (typeof curr === 'object' && curr !== null && !(curr instanceof Date)) {
            return Object.assign(Object.assign({}, acc), flattenNestedObject(obj[key], fullPath));
        }
        return Object.assign(Object.assign({}, acc), { [fullPath]: toString(curr) });
    }, {});
}
/**
 * Converts a given value to its string representation.
 * Handles dates, null, undefined, and scalar values.
 * - Dates are converted to ISO string format.
 * - Null and undefined are converted to an empty string.
 * - Other values are converted using their default string representation.
 *
 * @param value - The value to be converted to a string.
 * @returns The string representation of the value.
 */
function toString(value) {
    switch (true) {
        case value instanceof Date:
            return value.toISOString();
        case value === null || value === undefined:
            return '';
        default:
            return String(value);
    }
}
/**
 * Converts a string to Elixir-safe snake case
 *
 * Context: Lodash `snakeCase` function has a different behavior than Absinthe
 * for certain field names, specifically those ending in a number.
 *
 * Lodash: `snakecase('addressLine1')` => `address_line_1`
 * Absinthe: `Macro.underscore("addressLine1")` => `address_line1`
 *
 * This function matches the output of `Macro.underscore`
 *
 * @param str - string to convert to snake case
 */
function elixirSnakeCase(str) {
    const snakeCased = (0, lodash_1.snakeCase)(str);
    const numberRegex = /\d+$/;
    if (numberRegex.test(snakeCased)) {
        return snakeCased.split('_').reduce((prev, curr) => {
            return numberRegex.test(curr) ? `${prev}${curr}` : `${prev}_${curr}`;
        });
    }
    return snakeCased;
}
