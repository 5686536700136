"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEND_TEST_EMAIL = void 0;
const client_1 = require("@apollo/client");
const SEND_TEST_EMAIL = (0, client_1.gql) `
  mutation SendTestTransactionalEmail(
    $input: SendTestTransactionalEmailInput!
  ) {
    sendTestTransactionalEmail(input: $input) {
      status
    }
  }
`;
exports.SEND_TEST_EMAIL = SEND_TEST_EMAIL;
