"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.securityCopy = void 0;
exports.securityCopy = {
    header1: 'Change your password',
    paragraph1: 'We will email you a confirmation when changing your password, so please expect that email after submitting.',
    label1: 'Current Password',
    label2: 'New Password',
    label3: 'Confirm New Password',
    button1: 'Update Password'
};
