"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.apolloClient = void 0;
const client_1 = require("@apollo/client");
const context_1 = require("@apollo/client/link/context");
const error_1 = require("@apollo/client/link/error");
const queryDefinitions_1 = require("./queryDefinitions");
const cache_1 = require("./cache");
const Sentry = __importStar(require("./Sentry"));
const logout_1 = require("utils/logout");
Sentry.init();
const httpLink = (0, client_1.createHttpLink)({
    uri: env.GRAPHQL_URL
});
const authLink = (0, context_1.setContext)((_operation, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: Object.assign(Object.assign({}, headers), { authorization: token ? `Bearer ${token}` : undefined })
    };
});
// Log any GraphQL errors or network error that occurred
const errorLink = (0, error_1.onError)(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            // eslint-disable-next-line no-console
            console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Operation: ${operation.operationName}`);
            Sentry.captureException(message, { extra: { locations, path } });
        });
    }
    if (networkError) {
        if (networkError.message.match(/code 401/)) {
            (0, logout_1.handleLogout)();
        }
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${networkError}`);
    }
});
exports.apolloClient = new client_1.ApolloClient({
    link: errorLink.concat(authLink).concat(httpLink),
    cache: cache_1.cache,
    typeDefs: queryDefinitions_1.typeDefs
});
