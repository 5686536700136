"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitialParams = exports.setQueryParam = void 0;
const react_1 = __importStar(require("react"));
const setQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, JSON.stringify(value));
    const newurl = window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
};
exports.setQueryParam = setQueryParam;
const getInitialParams = (key, defaultOpt) => {
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(key);
    if (value) {
        try {
            return JSON.parse(value);
        }
        catch (_a) {
            return defaultOpt;
        }
    }
    return defaultOpt;
};
exports.getInitialParams = getInitialParams;
const useLocationSearchParams = () => {
    const [path, setPath] = react_1.default.useState(window.location.search);
    const listenToPopstate = () => {
        const winPath = window.location.search;
        setPath(winPath);
    };
    react_1.default.useEffect(() => {
        window.addEventListener('popstate', listenToPopstate);
        return () => {
            window.removeEventListener('popstate', listenToPopstate);
        };
    }, []);
    return path;
};
const useQueryParamState = (key, initialValue) => {
    const [value, setValue] = (0, react_1.useState)(() => getInitialParams(key, initialValue));
    const searchParams = useLocationSearchParams();
    const wrappedSetValue = (newValue) => {
        setQueryParam(key, newValue);
        setValue(newValue);
    };
    (0, react_1.useEffect)(() => {
        setValue(getInitialParams(key, initialValue));
    }, [searchParams, setValue]);
    return [value, wrappedSetValue];
};
exports.default = useQueryParamState;
