"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLogout = void 0;
const react_1 = require("react");
const logout_1 = require("utils/logout");
const useLogout = () => {
    return (0, react_1.useCallback)(() => {
        (0, logout_1.handleLogout)();
    }, []);
};
exports.useLogout = useLogout;
