"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeSelect = void 0;
const react_1 = __importStar(require("react"));
const polaris_1 = require("@shopify/polaris");
const lodash_1 = require("lodash");
// build a sorted theme options list and mark published theme
const buildThemeOptions = (themes) => (0, lodash_1.cloneDeep)(themes)
    .sort((a, b) => {
    // main theme (published shows up first)
    if (a.role === 'main') {
        return -1; // a should come before b
    }
    else if (b.role === 'main') {
        return 1; // b should come before a
    }
    // sort the rest alphabetically by name
    return a.name.localeCompare(b.name);
})
    // build option list and append "(Published)" next to `main` theme
    .map(({ id, name, role }) => ({
    label: role === 'main' ? `${name} (Published)` : name,
    value: id.toString()
}));
const ThemeSelect = (_a) => {
    var { themes } = _a, rest = __rest(_a, ["themes"]);
    const themeOptions = (0, react_1.useMemo)(() => buildThemeOptions(themes), [themes]);
    return react_1.default.createElement(polaris_1.Select, Object.assign({}, rest, { options: themeOptions }));
};
exports.ThemeSelect = ThemeSelect;
