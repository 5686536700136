"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordRecoveryCopy = void 0;
exports.passwordRecoveryCopy = {
    header: 'Reset Your Password',
    input1: 'Email Address',
    cta: 'Send Reset Link',
    prompt: 'Enter your email and we will send a link to change your password',
    heroHeader: 'New in Malomo: Tracking Page Creator',
    heroSubHeader: 'With just two clicks, you can create a new order tracking page that seamlessly integrates with your Shopify theme.',
    confirmation: "Request received! If you have an account with us, you'll receive an email to reset your password.",
    linkPrompt: 'Log in to your account',
    linkHref: '/login',
    errorTitle: 'Reset Password Error'
};
