"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatServerErrorMsg = void 0;
// TODO: Think about organization of static copy and where it should go.
// Also, rework error message string handling through app to use this function.
const FETCH_ERROR = 'The server could not be reached, please try again later.';
// Note: Expand as necessary. Returns basic errors based on existing logic for now.
function formatServerErrorMsg(error) {
    if (error.networkError) {
        return FETCH_ERROR;
    }
    return error.message;
}
exports.formatServerErrorMsg = formatServerErrorMsg;
