"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCarriers = void 0;
const react_1 = require("react");
const client_1 = require("@apollo/client");
const AccountContext_1 = require("contexts/AccountContext");
const use_carriers_graphql_1 = require("./use-carriers.graphql");
const useCarriers = () => {
    const { account } = (0, AccountContext_1.useAccount)();
    const [carrierChoiceList, setCarrierChoiceList] = (0, react_1.useState)([]);
    const [fetchCarriers, { loading, error }] = (0, client_1.useLazyQuery)(use_carriers_graphql_1.GET_CARRIERS, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { accountId: account.id },
        onCompleted: ({ carriers }) => {
            const carrierList = carriers.map((carrier) => ({
                label: carrier.name,
                value: carrier.code
            }));
            setCarrierChoiceList(carrierList);
        }
    });
    // Only fetch carrier list when account ID is available and list is empty
    const memoizedFetchCarriers = (0, react_1.useMemo)(() => () => {
        if ((account === null || account === void 0 ? void 0 : account.id) && carrierChoiceList.length === 0) {
            fetchCarriers();
        }
    }, [account === null || account === void 0 ? void 0 : account.id, carrierChoiceList, fetchCarriers]);
    return {
        error,
        loading,
        fetchCarriers: memoizedFetchCarriers,
        carrierChoiceList
    };
};
exports.useCarriers = useCarriers;
