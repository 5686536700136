"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_RULE_MUTATION = exports.GET_RULES_QUERY = void 0;
const client_1 = require("@apollo/client");
exports.GET_RULES_QUERY = (0, client_1.gql) `
  query GetRules($accountId: ID!) {
    rules(accountId: $accountId) {
      edges {
        node {
          id
          name
          enabled
          variables
        }
      }
    }
  }
`;
exports.UPDATE_RULE_MUTATION = (0, client_1.gql) `
  mutation UpdateRule($input: UpdateRuleInput!) {
    updateRule(input: $input) {
      rule {
        id
      }
    }
  }
`;
