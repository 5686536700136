"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeStyledMargins = void 0;
const computeStyledMargins = (props) => {
    const marginCss = [];
    const margin = props.margin || {};
    // allow override
    if (typeof margin === 'string') {
        return `margin: ${margin};`;
    }
    // Allow passing 10 for "10px"
    Object.keys(margin).forEach((key) => {
        if (typeof margin[key] === 'number') {
            margin[key] = margin[key] + 'px';
        }
    });
    if (margin.top) {
        marginCss.push(`margin-top: ${margin.top};`);
    }
    if (margin.right) {
        marginCss.push(`margin-right: ${margin.right};`);
    }
    if (margin.bottom) {
        marginCss.push(`margin-bottom: ${margin.bottom};`);
    }
    if (margin.left) {
        marginCss.push(`margin-left: ${margin.left};`);
    }
    return marginCss.join('\n');
};
exports.computeStyledMargins = computeStyledMargins;
