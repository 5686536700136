"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderModal = void 0;
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const AccountContext_1 = require("contexts/AccountContext");
const react_error_boundary_1 = require("react-error-boundary");
const ModalContent_1 = require("./ModalContent");
const graphql_1 = require("components/Dashboard/SearchableOrders/graphql");
const OrderModal = ({ display, orderId, handleToggleModal }) => {
    const handleError = (0, react_error_boundary_1.useErrorHandler)();
    const { account } = (0, AccountContext_1.useAccount)();
    const [getOrderDetails, { error, loading, data }] = (0, client_1.useLazyQuery)(graphql_1.GET_ORDER_DETAILS);
    (0, react_1.useEffect)(() => {
        if (account === null || account === void 0 ? void 0 : account.id) {
            getOrderDetails({ variables: { accountId: account === null || account === void 0 ? void 0 : account.id, id: orderId } });
        }
    }, [account === null || account === void 0 ? void 0 : account.id, orderId]);
    (0, react_1.useEffect)(() => {
        if (display) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.removeAttribute('style');
        }
    }, [display]);
    function handleClick(event) {
        if (event.target.id === 'modal-root') {
            handleToggleModal();
        }
    }
    if (error) {
        handleError(error);
    }
    return (react_1.default.createElement(ModalContent_1.ModalContent, { display: display, handleClick: handleClick, handleToggleModal: handleToggleModal, loading: loading, order: data === null || data === void 0 ? void 0 : data.order }));
};
exports.OrderModal = OrderModal;
