"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hourlyFormat = exports.shortDateFormat = exports.twoDigitDateFormat = void 0;
function twoDigitDateFormat(timestamp) {
    return new Date(timestamp).toLocaleDateString([], {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit'
    });
}
exports.twoDigitDateFormat = twoDigitDateFormat;
function shortDateFormat(timestamp) {
    return new Date(timestamp).toLocaleDateString([], {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
    });
}
exports.shortDateFormat = shortDateFormat;
function hourlyFormat(timestamp) {
    return new Date(timestamp).toLocaleTimeString([], {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric'
    });
}
exports.hourlyFormat = hourlyFormat;
