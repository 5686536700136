"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ORDER = void 0;
const client_1 = require("@apollo/client");
exports.GET_ORDER = (0, client_1.gql) `
  query order($accountId: ID!, $id: ID!) {
    order(accountId: $accountId, id: $id) {
      id
      insertedAt
      number
      name
      orderManagementUrl
      placedAt
      url
      customer {
        email
        firstName
        id
        lastName
        orderCount
        phoneNumber
      }
      shippingAddress {
        address1
        address2
        city
        country
        firstName
        lastName
        provinceCode
        zip
      }
      shipments {
        id
        insertedAt
        arrival
        trackingCode
        carrierUrl
        carrier
        carrierName
        insertedAt
        scanEvents {
          location {
            city
            country
            state
          }
          message
          description
          scannedAt
        }
        status
        estimatedDeliveryDate
        originalEstimatedDeliveryDate
      }
      issues {
        edges {
          node {
            id
            insertedAt
            rule {
              name
            }
            shipment {
              id
            }
          }
        }
      }
    }
  }
`;
