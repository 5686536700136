"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_INVITATION_EMAIL = exports.INVITE_USER = exports.GET_USERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_USERS = (0, client_1.gql) `
  query users($accountId: ID!) {
    users(accountId: $accountId) {
      admin
      email
      id
      insertedAt
      name
      updatedAt
      weeklyPerformanceEmail
    }
  }
`;
exports.INVITE_USER = (0, client_1.gql) `
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      existingUser
      user {
        admin
        email
        id
        insertedAt
        name
        updatedAt
        weeklyPerformanceEmail
      }
    }
  }
`;
exports.REQUEST_INVITATION_EMAIL = (0, client_1.gql) `
  mutation requestInvitationEmail($input: RequestInvitationEmailInput!) {
    requestInvitationEmail(input: $input) {
      ok
    }
  }
`;
