"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleLogout = void 0;
const cache_1 = require("cache");
const AccountContext_1 = require("contexts/AccountContext");
const apolloClient_1 = require("apolloClient");
const handleLogout = () => {
    var _a;
    (0, cache_1.isLoggedInVar)(false);
    (_a = AccountContext_1.AccountContextRef.current) === null || _a === void 0 ? void 0 : _a.setAccount(null);
    localStorage.removeItem('token');
    localStorage.removeItem('account');
    apolloClient_1.apolloClient.cache.reset({ discardWatches: true });
};
exports.handleLogout = handleLogout;
