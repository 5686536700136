"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerCopy = void 0;
exports.registerCopy = {
    header: 'Sign up for Malomo',
    input1: 'Account Name',
    input2: 'Your Domain',
    input3: 'Name',
    input4: 'Email Address',
    input5: 'Create a Password',
    cta: 'Sign Up',
    linkPreface: 'Already have an account?',
    linkPrompt: 'Log in.',
    linkHref: '/login',
    heroHeader: 'New in Malomo: Tracking Page Creator',
    heroSubHeader: 'With just two clicks, you can create a new order tracking page that seamlessly integrates with your Shopify theme.',
    errorTitle: 'Registration Error'
};
