"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emailConstants = exports.emailCopy = void 0;
exports.emailCopy = {
    pageTitle: 'Transactional Emails',
    customizeEmailTemplatesHeader: 'Customize email templates',
    customizeEmailDesc: 'Set your sender email and change the look of email notifications your customers receive. Add your logo and color theme.',
    malomoEmailsHeader: 'Malomo Emails'
};
exports.emailConstants = {
    disableEmailsLink: 'https://help.gomalomo.com/csc/how-to-disable-shopify-notification-emails'
};
