"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EMAIL_TYPES = void 0;
const client_1 = require("@apollo/client");
exports.GET_EMAIL_TYPES = (0, client_1.gql) `
  query TransactionalEmailTypes($accountId: ID!) {
    transactionalEmailTypes(accountId: $accountId) {
      active
      description
      id
      name
      order
      shopifyPlus
      slug
    }
  }
`;
