"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatGraphqlErrors = void 0;
const lodash_1 = require("lodash");
/**
 * @param error: ApolloError
 * @returns An array of form hook tuples that include the string name of the field
 * that had an error, along with a FieldError object associated with the field.
 *
 * Can be used to loop over and set form errors with via react-hook-form setError.
 */
const formatGraphqlErrors = (error) => {
    var _a;
    // handle server validation errors
    const hasValidationErrors = error.graphQLErrors.length && ((_a = error.graphQLErrors[0].extensions) === null || _a === void 0 ? void 0 : _a.field);
    const formErrors = [];
    if (hasValidationErrors) {
        error.graphQLErrors.forEach(({ extensions, message }) => {
            const fieldPath = extensions.field_path.split('.');
            const fieldName = fieldPath.length > 1
                ? `${fieldPath[0]}.${(0, lodash_1.camelCase)(fieldPath[1])}`
                : (0, lodash_1.camelCase)(fieldPath[0]);
            formErrors.push([fieldName, { type: 'validate', message }]);
        });
        return formErrors;
    }
    // for any leftover errors not part of error.graphQLErrors, handle as a server error
    return [['root.serverError', { type: 'server', message: error.message }]];
};
exports.formatGraphqlErrors = formatGraphqlErrors;
