"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteUserModal = void 0;
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const zod_1 = require("@hookform/resolvers/zod");
const zod_2 = require("zod");
const polaris_1 = require("@shopify/polaris");
const AccountContext_1 = require("contexts/AccountContext");
const client_1 = require("@apollo/client");
const graphql_1 = require("./graphql");
const lodash_1 = require("lodash");
const DEFAULT_FIELD_VALUES = {
    email: '',
    firstName: '',
    lastName: ''
};
const FIELD_LABELS = {
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name'
};
const schema = zod_2.z.object({
    email: zod_2.z
        .string()
        .min(1, { message: `${FIELD_LABELS.email} is a required field` })
        .email({ message: 'Please enter a valid email' }),
    firstName: zod_2.z
        .string()
        .min(1, { message: `${FIELD_LABELS.firstName} is a required field` }),
    lastName: zod_2.z
        .string()
        .min(1, { message: `${FIELD_LABELS.lastName} is a required field` })
});
const InviteUserModal = ({ active, onClose, onSuccess }) => {
    var _a, _b;
    const { account } = (0, AccountContext_1.useAccount)();
    const { control, setError, formState: { errors }, handleSubmit, reset } = (0, react_hook_form_1.useForm)({
        defaultValues: DEFAULT_FIELD_VALUES,
        resolver: (0, zod_1.zodResolver)(schema)
    });
    const onCloseModal = (0, react_1.useCallback)(() => {
        reset(DEFAULT_FIELD_VALUES);
        onClose();
    }, []);
    const [requestInvitationEmail] = (0, client_1.useMutation)(graphql_1.REQUEST_INVITATION_EMAIL);
    const [inviteUser, { loading }] = (0, client_1.useMutation)(graphql_1.INVITE_USER, {
        refetchQueries: [
            { query: graphql_1.GET_USERS, variables: { accountId: account === null || account === void 0 ? void 0 : account.id } },
            'users'
        ],
        onCompleted: ({ inviteUser: { existingUser, user } }) => {
            if (account) {
                requestInvitationEmail({
                    variables: {
                        input: {
                            accountId: account.id,
                            email: user.email,
                            existingUser
                        }
                    }
                });
            }
            onSuccess(`The user '${user.name}' has been invited to the account`);
            onCloseModal();
        },
        onError(error) {
            var _a;
            const hasValidationErrors = error.graphQLErrors.length && ((_a = error.graphQLErrors[0].extensions) === null || _a === void 0 ? void 0 : _a.field);
            if (hasValidationErrors) {
                error.graphQLErrors.forEach(({ extensions }) => {
                    const { field, message } = extensions;
                    const fieldName = (0, lodash_1.camelCase)(field);
                    setError(fieldName, {
                        type: 'validate',
                        message: `${FIELD_LABELS[fieldName]} ${message}`
                    });
                });
                return;
            }
            // handle all other server errors
            setError('root.serverError', {
                type: 'server',
                message: error.message
            });
        }
    });
    const onSubmit = handleSubmit((data) => {
        if (!account) {
            throw new Error('No account selected');
        }
        const { firstName, lastName } = data, rest = __rest(data, ["firstName", "lastName"]);
        inviteUser({
            variables: {
                input: Object.assign(Object.assign({}, rest), { accountId: account.id, name: `${firstName} ${lastName}` })
            }
        });
    });
    return (react_1.default.createElement(polaris_1.Modal, { onClose: onCloseModal, open: active, primaryAction: {
            content: 'Invite a User',
            loading,
            onAction: onSubmit
        }, secondaryActions: [
            {
                content: 'Cancel',
                onAction: onCloseModal
            }
        ], title: 'Invite a User' },
        react_1.default.createElement(polaris_1.Modal.Section, null,
            react_1.default.createElement("form", null,
                react_1.default.createElement(polaris_1.FormLayout, null,
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'firstName', render: (_a) => {
                            var _b;
                            var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                            return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = errors.firstName) === null || _b === void 0 ? void 0 : _b.message, label: FIELD_LABELS.firstName })));
                        } }),
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'lastName', render: (_a) => {
                            var _b;
                            var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                            return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message, label: FIELD_LABELS.lastName })));
                        } }),
                    react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: 'email', render: (_a) => {
                            var _b;
                            var _c = _a.field, { ref: _ref } = _c, rest = __rest(_c, ["ref"]);
                            return (react_1.default.createElement(polaris_1.TextField, Object.assign({}, rest, { autoComplete: 'off', error: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message, label: FIELD_LABELS.email })));
                        } }),
                    ((_a = errors.root) === null || _a === void 0 ? void 0 : _a.serverError) && (react_1.default.createElement(polaris_1.InlineError, { fieldID: 'serverError', message: (_b = errors.root.serverError.message) !== null && _b !== void 0 ? _b : 'Oops! Something went wrong.' })))))));
};
exports.InviteUserModal = InviteUserModal;
