"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorBanner = void 0;
const react_1 = __importStar(require("react"));
const polaris_1 = require("@shopify/polaris");
const react_router_dom_1 = require("react-router-dom");
const ErrorBanner = ({ title, message }) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const onAction = (0, react_1.useCallback)(() => {
        if (location.pathname.includes('orders')) {
            navigate(-1);
            return;
        }
        navigate('/orders');
    }, [navigate, location]);
    return (react_1.default.createElement(polaris_1.Banner, { action: { content: 'Back to All Orders', onAction }, title: title, tone: 'warning' },
        react_1.default.createElement("p", { className: 'error-message' }, message)));
};
exports.ErrorBanner = ErrorBanner;
