"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDelighted = void 0;
const react_1 = require("react");
const scriptElementId = 'delighted-snippet';
const publicApiKey = 'thWZPpBVN6UupdNm';
// Injects Delighted Web Snippet
// https://app.delighted.com/docs/api/web
const mountScript = () => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('id', scriptElementId);
    script.setAttribute('type', 'application/javascript');
    script.setAttribute('defer', 'true');
    script.innerHTML = `
        !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"${publicApiKey}","delighted");
      `;
    head === null || head === void 0 ? void 0 : head.appendChild(script);
};
// Removes injected Delighted scripts
const unmountScript = () => {
    var _a, _b;
    // remove injector script
    (_a = document.querySelector(`#${scriptElementId}`)) === null || _a === void 0 ? void 0 : _a.remove();
    // remove script tag injected by the script after it was executed
    (_b = document.querySelector("script[src$='delighted.js']")) === null || _b === void 0 ? void 0 : _b.remove();
};
const useDelighted = ({ mount }) => {
    (0, react_1.useEffect)(() => {
        if (mount) {
            mountScript();
        }
        else {
            unmountScript();
        }
        return unmountScript;
    }, [mount]);
};
exports.useDelighted = useDelighted;
