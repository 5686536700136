"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageUpload = void 0;
const react_1 = __importStar(require("react"));
const polaris_1 = require("@shopify/polaris");
const image_upload_copy_1 = require("./image-upload.copy");
const IMAGE_TYPES = [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml'
];
const ImageUpload = ({ buttonText = 'Add image', helpText, label, name, dropZoneHintText, maxFileSizeKB, showHelpLink = false, onlyAllowImageTypes, handleImageUpload, handleRemoveImageUrl, uploadError, existingImageUrl }) => {
    const [file, setFile] = (0, react_1.useState)();
    const [rejectedFile, setRejectedFile] = (0, react_1.useState)();
    const [openFileDialog, setOpenFileDialog] = (0, react_1.useState)(false);
    const [dropError, setDropError] = (0, react_1.useState)(null);
    const acceptTypes = onlyAllowImageTypes && onlyAllowImageTypes.length
        ? onlyAllowImageTypes
        : IMAGE_TYPES;
    (0, react_1.useEffect)(() => {
        handleImageUpload(file);
    }, [file]);
    (0, react_1.useEffect)(() => {
        if (existingImageUrl) {
            setFile(undefined);
            setRejectedFile(undefined);
            setDropError(null);
        }
    }, [existingImageUrl]);
    (0, react_1.useEffect)(() => {
        if (uploadError) {
            setRejectedFile(file);
            setFile(undefined);
            setDropError(uploadError);
        }
    }, [uploadError]);
    function handleDropFile(files) {
        const currentFile = files[0];
        const maxSizeBytes = maxFileSizeKB * 1000;
        if (!acceptTypes.includes(currentFile === null || currentFile === void 0 ? void 0 : currentFile.type)) {
            setDropError(`${image_upload_copy_1.IMAGE_UPLOAD_COPY.errorFileTypePrefix} ${acceptTypes.join(', ')}`);
            setFile(undefined);
            setRejectedFile(currentFile);
            return;
        }
        if ((currentFile === null || currentFile === void 0 ? void 0 : currentFile.size) > maxSizeBytes) {
            setDropError(image_upload_copy_1.IMAGE_UPLOAD_COPY.errorFileSize);
            setFile(undefined);
            setRejectedFile(currentFile);
            return;
        }
        setFile(currentFile);
        setRejectedFile(undefined);
        setDropError(null);
    }
    function handleToggleOpenFileDialog() {
        setOpenFileDialog((currentOpenFileDialog) => !currentOpenFileDialog);
    }
    function handleRemoveImage() {
        setFile(undefined);
        setRejectedFile(undefined);
        setDropError(null);
        handleRemoveImageUrl();
    }
    return (react_1.default.createElement(polaris_1.BlockStack, { gap: '300' },
        renderFileError(),
        react_1.default.createElement(polaris_1.BlockStack, { gap: '100' },
            react_1.default.createElement(polaris_1.DropZone, { accept: acceptTypes.join(', '), allowMultiple: false, id: `${name}-dropzone`, label: label, labelAction: createHelpAction(), onClick: () => undefined, onDrop: handleDropFile, 
                // Disables 'drop zone' so our buttons on top of it can work
                onFileDialogClose: handleToggleOpenFileDialog, openFileDialog: openFileDialog, type: 'image', variableHeight: true },
                react_1.default.createElement(polaris_1.Box, { padding: '600' },
                    renderFileUploadedPreviewControls(),
                    renderFileUploadArea())),
            react_1.default.createElement(polaris_1.Text, { as: 'p', tone: 'subdued' }, helpText))));
    function createHelpAction() {
        if (showHelpLink) {
            return {
                id: `${name}-help-link`,
                content: image_upload_copy_1.IMAGE_UPLOAD_COPY.imageHelpLinkText,
                // TODO: Knowledgebase article doesn't exist yet, this is a placeholder
                url: 'https://help.gomalomo.com/csc/',
                target: '_blank'
            };
        }
        return undefined;
    }
    // Note: This is similar to DropZone.FileUpload but allows for more control
    // around the default drop zone size, as well as having the button itself
    // trigger the dialog window instead of the dropzone
    function renderFileUploadArea() {
        if (!file && !existingImageUrl) {
            return (react_1.default.createElement(polaris_1.BlockStack, { align: 'center', gap: '100', inlineAlign: 'center' },
                react_1.default.createElement(polaris_1.Button, { id: `${name}-upload-button`, onClick: handleToggleOpenFileDialog }, buttonText),
                react_1.default.createElement(polaris_1.Text, { as: 'p' }, dropZoneHintText)));
        }
        return undefined;
    }
    function renderFileUploadedPreviewControls() {
        if (existingImageUrl || file) {
            const preview = file
                ? window.URL.createObjectURL(file)
                : existingImageUrl;
            return (react_1.default.createElement(polaris_1.BlockStack, { align: 'center', gap: '300', inlineAlign: 'center' },
                react_1.default.createElement("img", { id: `${name}-img`, src: preview, style: { maxWidth: '400px' } }),
                react_1.default.createElement(polaris_1.Box, { width: '100%' },
                    react_1.default.createElement(polaris_1.ButtonGroup, { fullWidth: true, noWrap: true, variant: 'segmented' },
                        react_1.default.createElement(polaris_1.Button, { id: `${name}-change-button`, onClick: handleToggleOpenFileDialog }, "Change"),
                        react_1.default.createElement(polaris_1.Button, { id: `${name}-remove-button`, onClick: handleRemoveImage }, "Remove")))));
        }
        return undefined;
    }
    function renderFileError() {
        if (dropError) {
            return (react_1.default.createElement(polaris_1.Banner, { onDismiss: handleRemoveImage, title: rejectedFile === null || rejectedFile === void 0 ? void 0 : rejectedFile.name, tone: 'critical' },
                react_1.default.createElement(polaris_1.Text, { as: 'p' }, dropError)));
        }
        return undefined;
    }
};
exports.ImageUpload = ImageUpload;
