"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_ACCOUNT = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_ACCOUNT = (0, client_1.gql) `
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        defaultTrackingPageUrl
        id
        name
        primaryHostedDomain {
          token
        }
      }
    }
  }
`;
