"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cache = exports.isLoggedInVar = void 0;
const client_1 = require("@apollo/client");
exports.isLoggedInVar = (0, client_1.makeVar)(!!localStorage.getItem('token'));
exports.cache = new client_1.InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isLoggedIn: {
                    read() {
                        return (0, exports.isLoggedInVar)();
                    }
                }
            }
        }
    }
});
