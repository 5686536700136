"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalStorage = void 0;
const react_1 = require("react");
// NOTE: This wrapper around setState will be problematic if used in a
// useCallback or other memoization technique within a component as storedValue
// will be captured by the function scope and never update.
function useLocalStorage(key, initialValue) {
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = (0, react_1.useState)(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        }
        catch (error) {
            // If error also return initialValue
            // eslint-disable-next-line no-console
            console.error('Error reading item from local storage', error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that persists the
    // new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
        catch (error) {
            // A more advanced implementation would handle the error case
            // eslint-disable-next-line no-console
            console.error('Error storing item in local storage', error);
        }
    };
    return [storedValue, setValue];
}
exports.useLocalStorage = useLocalStorage;
