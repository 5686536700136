"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const polaris_1 = require("@shopify/polaris");
const client_1 = require("@apollo/client");
const AccountContext_1 = require("contexts/AccountContext");
const graphql_1 = require("./graphql");
const styles_1 = require("./styles");
const InviteUserModal_1 = require("./InviteUserModal");
function getInitials(name) {
    const initials = name
        .split(' ')
        .filter((word, index, arr) => index === 0 || index === arr.length - 1)
        .map((word) => word.charAt(0))
        .join('')
        .toLocaleUpperCase();
    return initials;
}
const Users = () => {
    const { account } = (0, AccountContext_1.useAccount)();
    const [inviteUserModalIsActive, setInviteUserModalIsActive] = (0, react_1.useState)(false);
    const [inviteUserToastIsActive, setInviteUserToastIsActive] = (0, react_1.useState)(false);
    const [inviteUserToastMessage, setInviteUserToastMessage] = (0, react_1.useState)('');
    const { data } = (0, client_1.useQuery)(graphql_1.GET_USERS, {
        skip: !account,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { accountId: account.id }
    });
    const toastMarkup = inviteUserToastIsActive ? (react_1.default.createElement(polaris_1.Toast, { content: inviteUserToastMessage, onDismiss: () => {
            setInviteUserToastMessage('');
            setInviteUserToastIsActive(false);
        } })) : null;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(InviteUserModal_1.InviteUserModal, { active: inviteUserModalIsActive, onClose: () => setInviteUserModalIsActive(false), onSuccess: (message) => {
                setInviteUserToastMessage(message);
                setInviteUserToastIsActive(true);
                setInviteUserModalIsActive(false);
            } }),
        react_1.default.createElement(styles_1.StyledPageContainer, null,
            react_1.default.createElement(polaris_1.Frame, null,
                react_1.default.createElement(polaris_1.Page, null,
                    react_1.default.createElement(polaris_1.Layout, null,
                        react_1.default.createElement(polaris_1.Layout.Section, null,
                            react_1.default.createElement(polaris_1.LegacyCard, null,
                                react_1.default.createElement(polaris_1.ResourceList, { alternateTool: react_1.default.createElement(polaris_1.Button, { onClick: () => setInviteUserModalIsActive(true), variant: 'primary' }, "Invite a User"), headerContent: 'Users', items: (data === null || data === void 0 ? void 0 : data.users) || [], renderItem: ({ id, name, email }) => {
                                        const media = (react_1.default.createElement(polaris_1.Avatar, { initials: getInitials(name), name: name, size: 'md' }));
                                        return (react_1.default.createElement(polaris_1.ResourceItem, { accessibilityLabel: `View details for ${name}`, id: id, media: media, 
                                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                                            onClick: () => { } },
                                            react_1.default.createElement(polaris_1.Text, { as: 'h3', fontWeight: 'bold', variant: 'bodyMd' }, name),
                                            react_1.default.createElement(polaris_1.Text, { as: 'p' }, email)));
                                    }, resourceName: { singular: 'user', plural: 'users' }, selectable: false, showHeader: true }))),
                        toastMarkup))))));
};
exports.default = Users;
