"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEmailTypesContext = exports.EmailTypesProvider = void 0;
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const AccountContext_1 = require("contexts/AccountContext");
const use_email_types_graphql_1 = require("./use-email-types.graphql");
const EmailTypesContext = (0, react_1.createContext)(undefined);
const EmailTypesProvider = ({ children }) => {
    var _a;
    const { account } = (0, AccountContext_1.useAccount)();
    const getEmails = (0, client_1.useQuery)(use_email_types_graphql_1.GET_EMAIL_TYPES, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { accountId: account.id },
        skip: !(account === null || account === void 0 ? void 0 : account.id),
        fetchPolicy: 'cache-first'
    });
    const { error, loading, data, refetch } = getEmails;
    const emails = (_a = data === null || data === void 0 ? void 0 : data.transactionalEmailTypes) !== null && _a !== void 0 ? _a : [];
    const sortedEmails = [...emails].sort((a, b) => a.order - b.order);
    return (react_1.default.createElement(EmailTypesContext.Provider, { value: {
            emailTypesError: error,
            emailTypesLoading: loading,
            emailTypes: sortedEmails,
            refetchEmailTypes: refetch
        } }, children));
};
exports.EmailTypesProvider = EmailTypesProvider;
const useEmailTypesContext = () => {
    const context = (0, react_1.useContext)(EmailTypesContext);
    if (context === undefined) {
        throw new Error('useEmailTypesContext must be used within a EmailTypesProvider');
    }
    return context;
};
exports.useEmailTypesContext = useEmailTypesContext;
