"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETE_CUSTOMER = exports.GET_CUSTOMERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_CUSTOMERS = (0, client_1.gql) `
  query customers(
    $accountId: ID!
    $q: String
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    customers(
      accountId: $accountId
      q: $q
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      edges {
        node {
          email
          firstName
          id
          insertedAt
          lastName
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
exports.DELETE_CUSTOMER = (0, client_1.gql) `
  mutation deleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      success
    }
  }
`;
