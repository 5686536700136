"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDebouncedIframeUrl = void 0;
const react_1 = require("react");
const utils_1 = require("./utils");
const useDebouncedIframeUrl = (params) => {
    const previewQueryString = (0, utils_1.objectToQueryString)(params);
    const iframeUrl = new URL(`${env.GRAPHQL_URL}/transactional_email/preview?${previewQueryString}`).toString();
    const [debouncedIframeUrl, setDebouncedIframeUrl] = (0, react_1.useState)(() => iframeUrl);
    (0, react_1.useEffect)(() => {
        const timeout = setTimeout(() => {
            setDebouncedIframeUrl(iframeUrl);
        }, 500);
        return () => clearTimeout(timeout);
    }, [iframeUrl]);
    return debouncedIframeUrl;
};
exports.useDebouncedIframeUrl = useDebouncedIframeUrl;
