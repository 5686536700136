"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
let displayTimeout;
let textTimeout;
function useToast(delay = 2500) {
    const animationDuration = 2000; // let the CSS fade out before we remove text
    const [text, setText] = (0, react_1.useState)('');
    const [display, setDisplay] = (0, react_1.useState)(false);
    const [status, setStatus] = (0, react_1.useState)('success');
    const timedSetMessage = (0, react_1.useCallback)((arg) => {
        // reset any existing toasts
        clearTimeout(displayTimeout);
        clearTimeout(textTimeout);
        setDisplay(false);
        setText(null);
        if (typeof arg === 'string') {
            setText(arg);
            setStatus('success');
        }
        else {
            setText(arg.message);
            setStatus(arg.status);
        }
        setDisplay(true);
        // hide when appropriate
        displayTimeout = setTimeout(() => {
            setDisplay(false);
            textTimeout = setTimeout(() => {
                setText(null);
            }, animationDuration);
        }, delay);
    }, []);
    return {
        setToastMessage: timedSetMessage,
        toastParams: { text, display, status }
    };
}
exports.default = useToast;
